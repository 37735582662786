import { Injectable } from '@angular/core';
import confetti from 'canvas-confetti';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { AssetlinksService } from './assetlinks.service';

@Injectable({
  providedIn: 'root'
})
export class ConfettiService {

  constructor(
    private assetLinks:AssetlinksService,
  ) { }

  fireWorksWithAudio(){
    let audioContext = new (window.AudioContext || window['webkitAudioContext'])();
    let source;
    let analyser = audioContext.createAnalyser();
    let array;
    
    let request = new XMLHttpRequest();
    request.open('GET', this.assetLinks.fireWorksWav, true);
    request.responseType = 'arraybuffer';
    
    request.onload = () => {
        audioContext.decodeAudioData(request.response, (buffer) => {
            source = audioContext.createBufferSource(); 
            source.buffer = buffer;
            source.connect(analyser);
            analyser.connect(audioContext.destination);
            source.start(0);
        });
    }

    request.send();

    const visualize=()=> {
      analyser.fftSize = 256;
      let bufferLength = analyser.frequencyBinCount; 
      array = new Uint8Array(bufferLength); 
  
      const draw = ()=> {
          requestAnimationFrame(draw);
  
          analyser.getByteFrequencyData(array);
  
          // For simplicity, we'll consider 'bangs' to be when the value at the first index is greater than some threshold.
          if(array[0] > 200) {
             
              this.confettiCannonBoom(this.randomInRange(0.1, 0.9),this.randomInRange(0.1, 0.9),ImpactStyle.Heavy)
          }
          if(array[0]>150&&array[0]<200){
             // Change the background color when the bang happens
              confetti({
                particleCount: 3,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
              });
              confetti({
                particleCount: 3,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
              });
          }
      }
      draw();
    }

    visualize();
  }

  /**
   * 
   * @param x position from left to right 0 to 1
   * @param y top to bottom? 0 to 1?
   * @param impact 
   */
  confettiCannonBoom(x,y,impact:ImpactStyle){
    let colors = ['#fe5f55', '#7a9e9f','#ffffff'];
    let count = 200;
    let defaults = {
      origin: { y,x },
      ticks: 60,
      colors
    };

    function fire(particleRatio, opts) {
      confetti(Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      }));
    }

    Haptics.impact({style:impact})

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    fire(0.2, {
      spread: 60,
    });

    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
    
  }

  /**
   * Specify the max canon shots you want with maxBursts 
   * and in how many seconds the total bursts should fire.
   * i.e confettiCannonBurst(5,2) will fire between 1 and 5 shots within 2 seconds
   * confettiCannonBurst(10,1) will rapid fire
   * confettiCannonBurst(10,10) will slow fire
   * @param maxBursts 
   * @param maxDuration 
   */
  confettiCannonBurst(maxBursts:number,maxDuration:number,position?:{x:number,y:number}){
    if(!maxBursts || maxBursts <1){
      maxBursts = 1
     
    }
    if(!maxDuration|| maxDuration<1){
      maxDuration = 1
    }

    for (let index = 0; index < this.randomInRange(1, maxBursts); index++) {
      let shotInterval = this.randomInRange(100, maxDuration*1000)
      let impacts = [ImpactStyle.Light,ImpactStyle.Medium,ImpactStyle.Heavy]
      let impact = impacts[this.randomInRange(0, impacts.length)]
      setTimeout(()=>{
        this.confettiCannonBoom(
          position?position.x:this.randomInRange(0.1, 0.9),
          position?position.y:this.randomInRange(0.1, 0.9),
          impact
        )
      },shotInterval)
    }
  }

   /**
    * 
    * @param seconds 
    * @returns whatever setInterval() returns. Stop the fireworks by passing interval into a clearInterval() function;
    */
   confettiFireWorks(seconds:number){
    let colors = ['#fe5f55', '#7a9e9f','#cfbd54'];
    let duration = seconds * 1000;
    let animationEnd = Date.now() + duration;
    let defaults = { startVelocity: 30, spread: 360, ticks: 120, zIndex: 0,colors };
    let particlesPerExplosion = 50

    let interval = setInterval(()=> {
  
      let timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      let particleCount = particlesPerExplosion * (timeLeft / duration);
      let tapIntensity = ImpactStyle.Heavy 
      if(particleCount < (particlesPerExplosion/2)){
        tapIntensity = ImpactStyle.Medium
      }
      if(particleCount < particlesPerExplosion/4){
        tapIntensity = ImpactStyle.Light
      }

      Haptics.impact({ style: tapIntensity});
      let shotInterval = this.randomInRange(250, 500)
      setTimeout(()=>{
            // since particles fall down, start a bit higher than random
            confetti(Object.assign({}, defaults, { particleCount, origin: { x: this.randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
            confetti(Object.assign({}, defaults, { particleCount, origin: { x: this.randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
      },shotInterval)


    }, 500);

    return interval
   }

   confettiStream(seconds:number){
    let end = Date.now() + (seconds * 1000);
    // go YumDealz!
    let colors = ['#fe5f55', '#7a9e9f','#ffffff'];

    let frameCounter = 0;

    (function frame() {

      Haptics.impact({ style: ImpactStyle.Medium });

      confetti({
        particleCount: 3,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
      });
      confetti({
        particleCount: 3,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
      });

      // On every 10th frame, trigger a light impact
      if (frameCounter % 10 === 0) {
        Haptics.impact({ style: ImpactStyle.Light });
      }

      if (Date.now() < end) {
        frameCounter += 1;
        requestAnimationFrame(frame);
      }
    }());
   }

   private randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

}
