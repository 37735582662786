import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Geolocation, PermissionStatus } from "@capacitor/geolocation";
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Location, Deal } from '../models/interfaces';
import { LoadingService } from './loading.service';
import { ToastServiceService } from './toast-service.service';
import { NativeSettingsService } from './native-settings.service';
import { YumdealzUserService } from './yumdealz-user.service';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private _userLocation: Location = {
    lat: null,
    lng: null,
    readableAddress: null,
    accuracy: null,
    radius: null,
  }
  get userLocation() {
    return this._userLocation
  }
  searchLocation: Location = {
    lat: null,
    lng: null,
    readableAddress: null,
    accuracy: null,
    radius: null
  }
  private readonly defaultRadius = 5;
  private watchId: string = null;
  get isWatching() {
    return this.watchId ? true : false;
  }
  private userLocationSteam: BehaviorSubject<{ lat: number, lng: number }> = new BehaviorSubject({ lat: 0, lng: 0 })
  constructor(
    public loadingService: LoadingService,
    public toasts: ToastServiceService,
    private alertController: AlertController,
    private yumdealzUserService: YumdealzUserService,
    private nativeSettingsService: NativeSettingsService,
    private ts: TranslationService
  ) { }


  async getCurrentUserLocation(rationale: string = null, fresh = false) {
    try {
      if (this.isUserLocationSet() && !fresh) {
        return this.userLocation;
      } else {
        if (fresh) {
          let loc = await this.calcCurrentUserLocation(rationale);
          return loc;
        } else {
          return null
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Tries to get user location if it can.
   * Only use this if location permission is granted
   * and location is turned on. Also, It will not error
   * if it fails, not will it ask the user to open settings
   * if permission was denied. Use the getCurrentUserLocation
   * method for that.
   */
  async attemptSilentUserLocationFetch(){
    await this.tryGetCurrentPosition(true)
    if(this.isUserLocationSet()) this.setSearchLocation(this._userLocation);
    console.log(this._userLocation);
    
  }

  isSearchLocationSet(){
     if(this.searchLocation && this.searchLocation.lat != null &&
    this.searchLocation.lng  != null &&
    this.searchLocation.readableAddress != null  &&
    this.searchLocation.lat  != null  &&
    this.searchLocation.accuracy  != null &&
    this.searchLocation.radius != null ){
      return true
    } else {
      return false
    }
  }

  isUserLocationSet() {
    if (
      this._userLocation &&
      this._userLocation.lat != null &&
      this._userLocation.lng != null &&
      this._userLocation.accuracy != null
    ) {
      return true
    } else {
      return false
    }
  }

  async setUserLocationAsSearchLocation() {
    await this.getCurrentUserLocation();
    if (this.isUserLocationSet) {
      this.setSearchLocation(this.userLocation);
    }
  }

  getSearchLocation() {
    if (!this.isSearchLocationSet()) {
      return null
    }
    return this.searchLocation
  }

  setSearchLocation(location: Location) {
    this.searchLocation.lat = location.lat;
    this.searchLocation.lng = location.lng;
    this.searchLocation.readableAddress = location.readableAddress;
    this.searchLocation.accuracy = location.accuracy;
    this.searchLocation.radius = location.radius;
    this.yumdealzUserService.updateUserLastSearchLocation(this.searchLocation);
  }

  async watchUserLocation(rationale: string) {
    let mayUseLocation = await this.mayAllowLocation(rationale);

    if (!mayUseLocation) {
      return
    }

    if (this.watchId) {
      console.log('stop previous watch first with stopWatchingUserLocation');
      return;
    }
    this.watchId = await Geolocation.watchPosition({
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: Infinity,
    }, async (position, err) => {
      if (err) {
        await this.handleGeolocationError(err);
        return;
      }
      this._userLocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        radius: this._userLocation.radius || 5,
        readableAddress: this._userLocation.readableAddress || this.ts.getLocalizedValue('LOCATION_SERVICE.YOUR_LOCATION'),
        accuracy: position.coords.accuracy
      }
      this.pushNewLocationToUserLocationSteam({ lat: this._userLocation.lat, lng: this._userLocation.lng })
    })
  }

  async stopWatchingUserLocation() {
    if (!this.watchId) {
      console.log('already not watching');
      return;
    }
    await Geolocation.clearWatch({ id: this.watchId })
    this.watchId = null;
  }

  returnUserLocationStream() {
    return this.userLocationSteam
  }

  private async calcCurrentUserLocation(rationale: string) {
    let mayUseLocation = await this.mayAllowLocation(rationale);
    if (!mayUseLocation) {
      return null;
    }
    await this.tryGetCurrentPosition()
  }

  /**
   * Tries to get user location
   * @param showLoasilentding 
   * @returns 
   */
  private async tryGetCurrentPosition(silent = false){
    if(!silent) await this.loadingService.showOrContinueShowingLoading();
    try {
      let resp = await Geolocation.getCurrentPosition({
        timeout: 10000,
        enableHighAccuracy:true
      })
      if(!silent) await this.loadingService.hideLoading();
      this._userLocation = {
        accuracy:resp.coords.accuracy,
        lat:resp.coords.latitude,
        lng:resp.coords.longitude,
        readableAddress: this.ts.getLocalizedValue('LOCATION_SERVICE.YOUR_LOCATION'),
        radius:this.defaultRadius
      }
      this.pushNewLocationToUserLocationSteam({lat: this._userLocation.lat,lng: this._userLocation.lng})
      return location
     } catch (error) {
      if(!silent){
        await this.handleGeolocationError(error)
        await this.loadingService.hideLoading();
      } 
      return null
     }
  }

  private pushNewLocationToUserLocationSteam(location: { lat: number, lng: number }) {
    this.userLocationSteam.next({ lat: location.lat, lng: location.lng })
  }

  async checkLocationPermissions() {
    return Geolocation.checkPermissions()
  }

  private async mayAllowLocation(rationale: string) {

    let state: PermissionStatus

    try {
      state = await this.checkLocationPermissions()
    } catch (error) {
      await this.toasts.showToast(
        this.ts.getLocalizedValue('LOCATION_SERVICE.LOCATION_TURNED_OFF'),
        '')
      console.log(error);
      return false
    }

    if (!state) {
      return false
    }

    if (state.location == 'granted') {
      return true
    }

    if (state.location == 'denied') {
      await this.toasts.showToast(this.ts.getLocalizedValue('LOCATION_SERVICE.YOU_HAVE_DECLINED_ACCESS_TO_LOCATION'),
        this.ts.getLocalizedValue('LOCATION_SERVICE.LOCATION_FEATURES_UNAVAILABLE'),
        async () => {
          await this.nativeSettingsService.openNativeAppSettings()
        },
        this.ts.getLocalizedValue('LOCATION_SERVICE.OPEN_SETTINGS'))
      return false
    } else {
      let allowed = false;

      if (!rationale) {
        return await this.requestLocationPermissions();
      }

      let explainAlert = await this.alertController.create({
        message: rationale,
        cssClass: `custom-alert-shape`,
        mode: 'ios',
        buttons: [
          {
            text: this.ts.getLocalizedValue('LOCATION_SERVICE.NOT_NOW'),
            handler: async () => {
              allowed = false
            }
          },
          {
            text: this.ts.getLocalizedValue('LOCATION_SERVICE.YUM') + '!',
            handler: async () => {
              allowed = await this.requestLocationPermissions();
            }
          },
        ]
      });

      await explainAlert.present();

      return explainAlert.onDidDismiss().then(() => {
        return allowed
      });
    }
  }

  private async permissionsPassed(status: PermissionStatus) {
    switch (status.location) {
      case 'denied':
      case 'prompt':
      case 'prompt-with-rationale':
        return false
      default:
        return true;
    }
  }

  private async requestLocationPermissions() {
    try {
      if (Capacitor.getPlatform() == 'web') {
        return true
      }
      const status = await Geolocation.requestPermissions();
      return this.permissionsPassed(status)
    } catch (error) {
      await this.toasts.showToast(this.ts.getLocalizedValue('LOCATION_SERVICE.LOCATION_TURNED_OFF'), '')
      return false
    }
  }

  async handleGeolocationError(error) {
    if (!error.code || error.message == undefined) {
      console.log(error, 'was not a geolocation error');
    }

    switch (error.code) {
      case 1:
        //PERMISSION_DENIED
        console.log(error.message);
        await this.toasts.showToast(this.ts.getLocalizedValue('LOCATION_SERVICE.DENIED_LOCATION_ACCESS'), '')
        break;
      case 2:
        // POSITION_UNAVAILABLE
        await this.toasts.showToast(this.ts.getLocalizedValue('LOCATION_SERVICE.COULD_NOT_GET_LOCATION'), '')
        console.log(error.message);
        break;
      case 3:
        //TIMEOUT
        await this.toasts.showToast(this.ts.getLocalizedValue('LOCATION_SERVICE.LOCATION_TIMEOUT'), '')
        console.log(error.message);
        break;

      default:
        await this.toasts.showErrorToast(error.message)
        break;
    }

  }
}
