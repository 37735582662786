import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DaysLeftPipe } from "./days-left.pipe";

@NgModule({
    declarations:[
        DaysLeftPipe
    ],
    imports:[
        CommonModule,
    ],
    providers:[],
    exports:[
        DaysLeftPipe
    ]
})
export class PipesModule {}