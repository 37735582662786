import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Share } from '@capacitor/share';
import { AlertController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { DayTradingHours, Deal, Shop } from 'src/app/models/interfaces';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { AuthService } from 'src/app/services/auth.service';
import { CloudfunctriggersService } from 'src/app/services/cloudfunctriggers.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NetworkStatusService } from 'src/app/services/network-status.service';
import { ShopDealzService } from 'src/app/services/shop-dealz.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { TranslationService } from 'src/app/services/translation.service';
import { YumdealzUserService } from 'src/app/services/yumdealz-user.service';

@Component({
  selector: 'app-loyalty-lane-card',
  templateUrl: './loyalty-lane-card.component.html',
  styleUrls: ['./loyalty-lane-card.component.scss'],
})
export class LoyaltyLaneCardComponent implements OnInit {
  @Input() shop: Shop;
  @Input() deal: Deal;
  @Input() cardHeight = '50vh'
  @Output() onWantsToFlipBack = new EventEmitter<any>();
  tradingHours: DayTradingHours[] = [];
  statusColor: string;
  today: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
  tradingStatus: 'Open' | 'Closed' | 'Opening soon';
  isSpecialToday: boolean;

  constructor(
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public loadingService: LoadingService,
    public shopsDealzService: ShopDealzService,
    public assetLinksService: AssetlinksService,
    public authService: AuthService,
    public toastCtrl: ToastController,
    public cloudTriggers: CloudfunctriggersService,
    public dateTimeService: DateTimeService,
    private yumDealzUserService: YumdealzUserService,
    public toasts: ToastServiceService,
    public networkStatusService: NetworkStatusService,
    private ts: TranslationService,
  ) { }

  async ngOnInit() {
    this.today = this.dateTimeService.giveLocalNowDay()
    this.isSpecialToday = this.deal.days.includes(this.today) ? true : false;
    try {
      this.parseTradingHours();
    } catch (error) {
      console.error(error)
    }
  }

  flipBack() {
    this.onWantsToFlipBack.emit()
  }

  arrayMaker(total: number) {
    let returnArray = []
    for (let i = 0; i < total; i++) {
      returnArray.push(i)
    }
    return returnArray
  }

  parseTradingHours() {
    const timeState = this.shopsDealzService.isShopAvailableNowOrLaterOrMissed(this.shop, this.today);
    switch (timeState) {
      case 'later':
        this.statusColor = 'warning'
        break;
      case 'now':
        this.statusColor = 'success'
        break;
      case 'missed':
        this.statusColor = 'danger'
        break;
      default:
        this.statusColor = 'medium'
        break;
    }
    if (!this.deal.days.includes(this.today)) {
      this.statusColor = 'medium'
    }
    let today = this.dateTimeService.giveLocalNowDayAbreviated();
    this.shop.normal_trading_days.forEach(day => {
      let activated = false;
      let abbreviation = day.substr(0, 3).toUpperCase();
      if (abbreviation == today) {
        activated = true;
      }
      this.tradingHours.push({
        activated,
        day,
        abbreviation,
        from: this.shopsDealzService.giveShopDayFromTradingHours(this.shop, day),
        to: this.shopsDealzService.giveShopDayToTradingHours(this.shop, day)
      })
    });
    this.sortTradingHours();
    this.determineTradingStatus();
  }

  sortTradingHours() {
    this.tradingHours.sort((a, b) => {
      const dayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'holidays'];
      const aIndex = dayOrder.indexOf(a.day.toLowerCase());
      const bIndex = dayOrder.indexOf(b.day.toLowerCase());
      return aIndex - bIndex;
    });
  }

  determineTradingStatus() {
    let today = this.tradingHours.find((day) => {
      return day.activated
    })
    let timeRelativeTo: string
    try {
      timeRelativeTo = this.dateTimeService.whereIsCurrentTimeRelativeTo(today.from, today.to);
    } catch (error) {
      // the try will fail if today is a day not within the restaurant's trading days
    }
    switch (timeRelativeTo) {
      case 'before':
        this.tradingStatus = 'Opening soon'
        break;
      case 'between':
        this.tradingStatus = 'Open'
        break;
      default:
        this.tradingStatus = 'Closed'
    }
  }

  share() {
    Share.share({
      text: `Check it out! ${this.deal.restaurant_name} is giving out this loyalty card - ${this.deal.name}: ${this.deal.description}. Download YumDealz now to discover more dealz like this in your area`,
      title: this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.DEAL_FOUND_ON_YUMDEALZ'),
      url: `https://yumdealz.com`,
      dialogTitle: this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.SHARE_DEAL')
    }
    )
  }

  async showSpecialDays() {
    let alert = await this.alertCtrl.create({
      message: this.deal.days.join(', '),
      header: this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.AVAILABLE_ON') + ':',
      cssClass: `custom-alert-shape`,
      mode: 'ios',
    })
    alert.present()
  }

  return(data = null) {
    this.modalCtrl.dismiss();
  }

  async like() {
    if (!this.yumDealzUserService.getUser().likedSpecials.includes(this.deal.id)) {
      let alert = await this.alertCtrl.create({
        header: this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.LIKE_THIS') + '?',
        message: this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.LET_THE_RESTAURANT_KNOW_BY_GIVING_LIKE'),
        cssClass: `custom-alert-shape`,
        mode: 'ios',
        buttons: [
          {
            text: this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.NOT_NOW'),
            role: 'cancel',
          },
          {
            text: this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.LIKE'),
            handler: async () => {
              await this.loadingService.showOrContinueShowingLoading();
              this.cloudTriggers.likeSpecial(this.deal, this.yumDealzUserService.getUser()).then(async () => {
                this.deal.likes ? this.deal.likes = this.deal.likes + 1 : this.deal.likes = 1
                this.yumDealzUserService.refreshUser().then(async () => {
                  await this.loadingService.hideLoading();
                  await this.toasts.showToast(this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.THANKS') + '!', this.ts.getLocalizedValue('LIKED_DEAL'))
                }).catch(async (err) => {
                  console.log(err);
                  await this.loadingService.hideLoading();
                })
              }).catch(async (err) => {
                await this.loadingService.hideLoading();
                await this.toasts.showToast(this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.ARE_YOU_OFFLINE') + '?', this.ts.getLocalizedValue('FAILED_TO_LIKE_DEAL'))
              })
            }
          }
        ]
      })
      await alert.present();
    } else {
      await this.toasts.showToast(this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.THANKS_AGAIN') + '!', this.ts.getLocalizedValue('YOU_ALREADY_LIKED_THIS_DEAL'))
    }
  }

  activateTradingHourday(day: DayTradingHours) {
    this.tradingHours.forEach(tradingday => {
      tradingday.activated = false;
      if (tradingday.day == day.day) {
        tradingday.activated = true;
      }
    });
  }

  sendMail() {
    try {
      location.href = `mailto:${this.shop.email}`;
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.COULD_NOT_OPEN_MAIL_APP'));
    }
  }

  call() {
    try {
      window.open(`tel:${this.shop.contact_number}`);
    } catch (error) {
      console.log(error);
      this.toasts.showErrorToast(this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.COULD_NOT_CALL'))
    }
  }

  async viewMap() {
    let googlemaps = `https://www.google.com/maps/search/?api=1&query=${this.deal.lat},${this.deal.lng}`
    try {
      window.open(googlemaps, '_system', 'location=yes');
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.COULD_NOT_OPEN_GOOGLE_MAPS'))
    }
  }

  //can remove this eventually as validation in merchant portal should
  //not allow non international format
  giveIntContactNumber(contactNumber: string) {
    let indexZero = '+27'
    let contactNumberSplit = contactNumber.split('');
    contactNumberSplit[0] = indexZero;
    let contactNumberNew = contactNumberSplit.join('');
    return contactNumberNew
  }

  openWhatsapp() {
    try {
      let SAIntNumber = this.shop.contact_number[0] == '+' ? this.shop.contact_number : this.giveIntContactNumber(this.shop.contact_number)
      let msg = encodeURI(`Good day. I have something to ask you regarding the ${this.deal.name} deal:`);
      window.open(`https://wa.me/${SAIntNumber}?text=${msg}`, '_system', 'location=yes');
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.COULD_NOT_OPEN_WHATSAPP'))
    }
  }

  openFacebookPage() {
    try {
      window.open(this.shop.facebook, '_system', 'location=yes')
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.COULD_NOT_OPEN_FACEBOOK_PAGE'))
    }
  }

  openInstaPage() {
    try {
      window.open(this.shop.instagram, '_system', 'location=yes')
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.COULD_NOT_OPEN_INSTAGRAM_PAGE'))
    }
  }

  viewReviews() {
    this.toasts.showToast(this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.REVIEW_FUNCTIONALITY_PLANNED_FOR_FUTURE_UPDATE'), this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.COMING_SOON'))
  }

  openWebsite() {
    try {
      window.open(this.shop.website, '_system', 'location=yes')
    } catch (error) {
      this.toasts.showToast('', this.ts.getLocalizedValue('LOYALTY_LANE_CARD_COMPONENT.COULD_NOT_OPEN_WEBSITE'))
    }
  }

  isAbbreviatedDayInSpecialDays(day: DayTradingHours) {
    return this.deal.days.includes(day.day)
  }
}
