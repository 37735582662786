import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-forgot-pw',
  templateUrl: './forgot-pw.page.html',
  styleUrls: ['./forgot-pw.page.scss'],
})
export class ForgotPwPage {
  email: string
  constructor(
    private loadingService: LoadingService,
    private auth: AuthService,
    private alertService: AlertService,
    private modalController: ModalController,
    public assetLinksService: AssetlinksService,
    private toasts: ToastServiceService,
    private ts: TranslationService
  ) {

  }



  async sendResetEmail() {
    try {
      this.email = this.email.trim();
      let loading = await this.loadingService.showOrContinueShowingLoading();
      loading.onDidDismiss().then(async () => {
        await this.cancel()
      })
      this.auth.resetPw(this.email).then(async () => {
        await this.loadingService.hideLoading();
        await this.alertService.yumDealzDefaultAlert(
          this.ts.getLocalizedValue('INFO.SUCCESS'),
          this.ts.getLocalizedValue('INFO.CHECK_INBOX_FOR_RECOVERY'),
          false, true
        )
      }, async err => {
        this.auth.handleAuthErrors(err)
        await this.loadingService.hideLoading();
      })
    } catch (error) {
      await this.loadingService.hideLoading();
      this.toasts.showErrorToast(error.message);
      console.log(error);
    }
  }


  async cancel() {
    await this.modalController.dismiss()
  }

}
