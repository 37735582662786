import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {
  getOptions:{source:'server'|'cache'}={
    source:'server'
  };
  status:'online'|'offline'='online';
  constructor() {
    this.initNetworkChecker();
   }

  isOnline(){
    return navigator.onLine;
  }

  giveFbGetOptions(){
    this.isOnline()?this.setFbGetOptions('server'):this.setFbGetOptions('server') //forcing server for now
    return this.getOptions
  }

  private initNetworkChecker(){
     window.addEventListener('online',()=>{
       if(navigator.onLine){
        this.setFbGetOptions('server');
       }
     })
     window.addEventListener('offline',()=>{
      if(!navigator.onLine){
        this.setFbGetOptions('cache')
       }
    })
  }

  private setFbGetOptions(from:'cache'|'server'){
    this.getOptions={
      source:from
    }
  }
}
