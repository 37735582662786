import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
    selector: 'app-form-control-feedback',
    templateUrl: './form-control-feedback.component.html',
    styleUrls: ['./form-control-feedback.component.scss']
})
export class FormControlFeedbackComponent {
    @Input()
    /*Required*/
    control: AbstractControl<any, any>;

    @Input()
    /*Preferably set this to get decent default messages. */
    translateNameKey: string;

    @Input()
    /*Set to true when your inputField is of type date*/
    isDate = false;

    @Input()
    /*Optionally set this to override the default message for required error.*/
    required: string;

    @Input()
    /*Optionally set this to override the default message for minlength error.*/
    minLength: string;

    @Input()
    /*Optionally set this to override the default message for maxlength error.*/
    maxLength: string;

    @Input()
    /*Optionally set this to override the default message for min error.*/
    min: string;

    @Input()
    /*Optionally set this to override the default message for max error.*/
    max: string;

    @Input()
    /*Optionally set this to override the default message for pattern error.*/
    pattern: string;

    // private dateFormatMessage = `${this.translate.instant(
    //     'FORM_FEEDBACK.DATE_PATTERN'
    // )} ${this.dateUtilService.datePickerDateFormat()}`;

    constructor(
        private ts: TranslationService
    ) { }

    /**
     * NOTE the order of the if statements is important.
     */
    feedback(): string {
        if (this.control && this.control.errors) {
            if (this.control.errors.required) {
                return this.requiredMessage();
            } else if (this.control.errors.containsDigits) {
                return this.containsDigitsMessage();
            } else if (this.control.errors.containsLowerCase) {
                return this.containsLowerCaseMessage();
            } else if (this.control.errors.laterThanValidator) {
                return this.laterThanValidatorMessage();
            } else if (this.control.errors.earlierThanValidator) {
                return this.earlierThanValidatorMessage();
            } else if (this.control.errors.containsUpperCase) {
                return this.containsUpperCaseMessage();
            } else if (this.control.errors.pattern) {
                return this.invalidPatternMessage();
            } else if (this.control.errors.minlength) {
                return this.minLengthMessage();
            } else if (this.control.errors.maxlength) {
                return this.maxLengthMessage();
            } else if (this.control.errors.min) {
                return this.minMessage();
            } else if (this.control.errors.max) {
                return this.maxMessage();
            } else if (this.control.errors.confirmedValidator) {
                return this.passWordMissMatchMessage();
            }
            return this.ts.getLocalizedValue('ERRORS.INVALID_VALUE');
        }
    }

    private laterThanValidatorMessage() {
        return this.ts.getLocalizedValue('ERRORS.MUST_BE_EARLIER_THAN_TO_TIME')
    }

    private earlierThanValidatorMessage() {
        return this.ts.getLocalizedValue('ERRORS.MUST_BE_EARLIER_THAN_FROM_TIME')
    }

    private requiredMessage(): string {
        if (this.required) {
            return this.required;
        }
        return this.ts.getLocalizedValue('ERRORS.REQUIRED')
    }

    private minLengthMessage(): string {
        if (this.minLength) {
            return this.minLength;
        } else if (this.isDate) {
            return this.ts.getLocalizedValue('ERRORS.TOO_EARLY')
        }
        return this.ts.getLocalizedValue('ERRORS.TOO_SHORT')
    }

    private maxLengthMessage(): string {
        if (this.maxLength) {
            return this.maxLength;
        } else if (this.isDate) {
            return this.ts.getLocalizedValue('ERRORS.TOO_LATE')
        }
        return this.ts.getLocalizedValue('ERRORS.TOO_LONG')
    }

    private invalidPatternMessage() {
        if (this.pattern) {
            return this.pattern;
        } else if (this.isDate) {
            return this.ts.getLocalizedValue('ERRORS.INCORRECT_DATE_FORMAT')
        } else if (this.translateNameKey) {
            const ucKey = this.translateNameKey.toUpperCase();
            if (ucKey.indexOf('MAIL') >= 0) {
                return 'eg: example@gmail.com'
            } else if (ucKey.indexOf('PHONE') >= 0) {
                return 'eg: 0826767676'
            } else if (ucKey.indexOf('PASSWORD') >= 0) {
                return this.ts.getLocalizedValue('ERRORS.PASSWORD_FIELD_MUST_CONTAIN')
            }
        }
        return this.ts.getLocalizedValue('ERRORS.INVALID_FORMAT')
    }

    private minMessage() {
        if (this.min) {
            return this.min;
        } else if (this.isDate) {
            return this.ts.getLocalizedValue('ERRORS.MUST_BE_LATER')
        }
        return this.ts.getLocalizedValue('ERRORS.MUST_BE_MORE')
    }

    private maxMessage() {
        if (this.max) {
            return this.max;
        } else if (this.isDate) {
            return this.ts.getLocalizedValue('ERRORS.MUST_BE_SOONER')
        }
        return this.ts.getLocalizedValue('ERRORS.MUST_BE_LESS')
    }

    private passWordMissMatchMessage() {
        return this.ts.getLocalizedValue('ERRORS.PASSWORDS_DO_NOT_MATCH');
    }

    private containsDigitsMessage() {
        return this.ts.getLocalizedValue('ERRORS.MUST_CONTAIN_ALEAST_ONE_DIGIT');
    }

    private containsUpperCaseMessage() {
        return this.ts.getLocalizedValue('ERRORS.MUST_CONTAIN_ATLEAST_ONE_UPPERCASE_LETTER');
    }

    private containsLowerCaseMessage() {
        return this.ts.getLocalizedValue('ERRORS.MUST_CONTAIN_ATLEAST_ONE_LOWERCASE_LETTER');
    }

}

