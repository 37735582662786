import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * The sole purpose of this service is to inject it into other services that need
 * access to the user id as soon as they are instantiated.
 * We can subscribe to userId$ in their constructors and run their initialization
 * inside the observer fn.
 * 
 * setUserId would have to be called as soon as possible though and any service waiting
 * for the user id should handle the case where userId is undefined or null
 */

@Injectable({
  providedIn: 'root'
})
export class UserIdService {
  userId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor() { }

  setUserId(userId: string) {
    this.userId$.next(userId);
  }
}
