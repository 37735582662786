import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FirebaseApp, getApp as giveApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { Firestore, FirestoreSettings, PersistentCacheSettings, enableIndexedDbPersistence, getFirestore, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore';
import { Functions, getFunctions } from 'firebase/functions';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private app:FirebaseApp
  private db:Firestore
  private auth:Auth
  private functions:Functions

  constructor() {
      this.app = initializeApp(environment.firebaseConfig);
      this.db = initializeFirestore(this.app,{
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager(),
        })
      });
      this.functions = getFunctions(this.app);

    if (Capacitor.isNativePlatform()) {
      this.auth = initializeAuth(this.app, {
        persistence: indexedDBLocalPersistence,
      });
    } else {
      this.auth = getAuth(this.app);
    }
   }

   getAuth(){
    return this.auth
   }

   getDb(){
    return this.db
   }

   getFunctions(){
    return this.functions
   }

   getApp(){
    return this.app
   }
}
