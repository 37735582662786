import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nfc-disabled-stamp',
  templateUrl: './nfc-disabled-stamp.component.html',
  styleUrls: ['./nfc-disabled-stamp.component.scss'],
})
export class NfcDisabledStampComponent  implements OnInit {
  @Input() mode:'button'
  @Input() behavior:'static'|'animate'
  constructor() { }

  ngOnInit() {}

}
