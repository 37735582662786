import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { SwiperOptions, Virtual,Pagination,EffectCards,Scrollbar, EffectFlip  } from 'swiper';
import { Deal, ShopWithDealz } from 'src/app/models/interfaces';
import { GisService } from 'src/app/services/gis.service';
import { LocationService } from 'src/app/services/location.service';
// install Swiper modules
SwiperCore.use([Scrollbar]);

@Component({
  selector: 'app-shop-lane',
  templateUrl: './shop-lane.component.html',
  styleUrls: ['./shop-lane.component.scss'],
})
export class ShopLaneComponent  implements OnInit {
  @Input() shopWithDealz:ShopWithDealz
  @Input() cardHeight = '50vh'
  @Input() mode:'dealSelect'|'loyaltyJoin'='loyaltyJoin'
  @Output() onDealSelectEmit:EventEmitter<Deal> = new EventEmitter();
  kmFromUser:string;
  @ViewChild('swiper', { static: true }) swiper?: SwiperComponent;
  swiperConfig: SwiperOptions = {
    slidesPerView:'auto',
    centeredSlides:true,
    centeredSlidesBounds:true,
    scrollbar:true,
    // cardsEffect:{
    //   slideShadows:false,
    //   // transformEl:'ion-card'
    // },
    flipEffect:{
      transformEl:'ion-card'
    }
  };
  constructor(
    public assetLinksService:AssetlinksService,
    private modalCtrl:ModalController,
    private gisService:GisService,
    private locationService:LocationService
  ) { 
   
 
  }

  ngOnInit() {
    this.kmFromUser = this.calcKmFromShop()
  }

  dealSelected(deal){
    this.onDealSelectEmit.emit(deal);
    console.log('emitted from ShopLaneComponent',deal);
  }

  onSwiper(params) {
    const [swiper] = params;
    console.log(swiper);
  }
  
  onBeforeTransitionStart(params) {
    const [swiper, speed, internal] = params;
    console.log('beforeTransitionStart, speed: ' + speed);
  }
  
  onSlideChange() {
    console.log('slide change');
  }
  
  slideNext(){
    this.swiper.swiperRef.slideNext(500);
  }
  
  slidePrev(){
   this.swiper.swiperRef.slidePrev(500);
  }

  calcKmFromShop(){
  if(this.locationService.isUserLocationSet()){
    let kmFromSpecial = this.gisService.getDistanceFromLocation(
      this.shopWithDealz.lng,
      this.shopWithDealz.lat,
      {
        lng:this.locationService.userLocation.lng,
        lat:this.locationService.userLocation.lat
      }
    )
    let kmFromSpecialStr = kmFromSpecial.toFixed(2);
    return kmFromSpecialStr
  }
  }

}
