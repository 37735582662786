import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Pipe({
  name: 'daysLeft'
})
export class DaysLeftPipe implements PipeTransform {
  constructor(
    private ts: TranslationService
  ) {

  }
  transform(timestamp: number): string {
    const now = Date.now();
    const diff = timestamp - now;
    const daysLeft = Math.ceil(diff / (1000 * 60 * 60 * 24));

    const expiresInText = this.ts.getLocalizedValue('PIPES.DAYS_LEFT.EXPIRES_IN');
    const dayText = this.ts.getLocalizedValue('PIPES.DAYS_LEFT.DAY');
    const daysText = this.ts.getLocalizedValue('PIPES.DAYS_LEFT.DAYS');
    const expiredText = this.ts.getLocalizedValue('PIPES.DAYS_LEFT.EXPIRED');

    return daysLeft > 0 ?
      `${expiresInText} ${daysLeft} ${daysLeft === 1 ? `${dayText}` : `${daysText}`}` :
      `${expiredText}`
  }

}
