import { Injectable } from '@angular/core';
import * as turf  from "@turf/turf";
import { Location } from '../models/interfaces';
import * as geofire from 'geofire-common';

@Injectable({
  providedIn: 'root'
})
export class GisService {

  constructor() { }

  getDistanceFromLocation(toLng:number, toLat:number,fromLocation:{lng:number,lat:number}) {
    try {
      let from = turf.point([fromLocation.lng,fromLocation.lat]);
      let to = turf.point([toLng,toLat]);
      let distance = turf.distance(from, to);
      return distance
    } catch (error) {
      this.handleErrors(error);
    }
  }

  sortTheseObjectsByDistanceFrom(fromLocations:{lng:number,lat:number,[key:string]:any}[],toLng:number, toLat:number){
    fromLocations.forEach((location)=>{
        let kmFromSpecial = this.getDistanceFromLocation(location.lng,location.lat,{lng:toLng,lat:toLat})
        location.kmFromSpecial=kmFromSpecial;
    })
    fromLocations.sort((a,b) => (a.kmFromSpecial > b.kmFromSpecial) ? 1 : ((b.kmFromSpecial > a.kmFromSpecial) ? -1 : 0))
  }

  inRadius(longetude, lattitude, radius,location:Location|Location) {
    try {
      let buffer = this.getCurrentUserLocationBuffer(radius+((location.accuracy*2)/1000),location)
      let turfPoint = turf.point([parseFloat(longetude), parseFloat(lattitude)]);
      let isInBuffer = turf.booleanPointInPolygon(turfPoint, buffer);
      if (isInBuffer) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.handleErrors(error);
    }
  }
  
  getCurrentUserLocationBuffer(radius,location) {
    try {
      let preFormattedPosition = turf.point([location.lng,location.lat]);
      let buffered = turf.buffer(preFormattedPosition, radius);
      return buffered;
    } catch (error) {
      this.handleErrors(error);
    }
  }

  makeGeoHashFromLatLng(locationData:{
    lat:number,
    lng:number}){
       return geofire.geohashForLocation([locationData.lat, locationData.lng]);
    }

  private handleErrors(error){
    console.log(error);
    throw new Error('The gis service ran into a problem')
  }
}
