import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  static containsDigits(control: AbstractControl): { [key: string]: any } {
    const value = control.value;
    if (!ValidatorService.hasDigit(value)) {
        return {
            containsDigits: {
                value
            }
        };
    }
    return null;
}

/**
* When two fields should match
* @param controlName
* @param matchingControlName
* @returns ValidatorFn
*/
static confirmedValidator(
  controlName: string,
  matchingControlName: string
) {

  return (formGroup: FormGroup): ValidatorFn => {
    
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
          matchingControl.errors &&
          !matchingControl.errors.confirmedValidator
      ) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  };
}

static laterThanValidator(
  controlName: string,
  earlierTimeControlName: string
) {
  return (formGroup: FormGroup): ValidatorFn => {
      const control = formGroup.controls[controlName];
      const earlierControl = formGroup.controls[earlierTimeControlName];
      if (
        earlierControl.errors &&
          !earlierControl.errors.laterThanValidator
      ) {
          return;
      }
      
      if (control.value < earlierControl.value) {
        earlierControl.setErrors({ laterThanValidator: true });
      } else {
        earlierControl.setErrors(null);
      }
  };
}

static earlierThanValidator(
  laterTimeControlName: string,
  controlName: string
) {
  return (formGroup: FormGroup): ValidatorFn => {
      const control = formGroup.controls[controlName];
      const laterControl = formGroup.controls[laterTimeControlName];
      if (
        laterControl.errors &&
          !laterControl.errors.earlierThanValidator
      ) {
          return;
      }
      
      if (control.value > laterControl.value) {
        laterControl.setErrors({ earlierThanValidator: true });
      } else {
        laterControl.setErrors(null);
      }
  };
}
static containsLowerCase(control: AbstractControl): { [key: string]: any } {
    const value = control.value;
    if (!ValidatorService.hasLowerCase(value)) {
        return {
            containsLowerCase: {
                value
            }
        };
    }
    return null;
}

static containsUpperCase(control: AbstractControl): { [key: string]: any } {
    const value = control.value;
    if (!ValidatorService.hasUpperCase(value)) {
        return {
            containsUpperCase: {
                value
            }
        };
    }
    return null;
}

static isTodayInRange(validFrom: Date, validTo: Date): boolean {
    const today = new Date().valueOf();
    const start =
        validFrom != null ? validFrom.setHours(0, 0, 0, 0).valueOf() : null;
    const end =
        validTo != null ? validTo.setHours(23, 59, 59, 99).valueOf() : null;
    if (start == null && end == null) {
        return true;
    } else if (start == null) {
        return today <= end;
    } else if (end == null) {
        return today >= start;
    }
    return today >= start && today <= end;
}

static isPasswordSame(control: AbstractControl): { [key: string]: any } {
    const value = control.value;
    if (value) {
        return {
            isPasswordSame: {
                value
            }
        };
    }
    return null;
}

private static hasLowerCase(password) {
  return password.toUpperCase() !== password;
}

private static hasUpperCase(password) {
    return password.toLowerCase() !== password;
}

private static hasDigit(password: string) {
    const digitRegex = /\d/g;
    return digitRegex.test(password);
}
}
