<div mode="ios" class="card" *ngIf="special && !loading">
  <div class="card-content">
    <div  id="title-container">
      <h2>{{special.descriptive_tag}}</h2>
    </div>
    <div id="img-container" (click)="viewRestaurant(special)"class="fade-in">
      <img  
      id="background-image"
      [defaultImage]="assetLinksService.spinnerGif" 
      [lazyLoad]="special.picture" 
      [errorImage]="assetLinksService.favicon"
      onerror="this.src='assets/favicon.png'">
      <!-- <ion-spinner  name="dots"></ion-spinner> -->
    </div>
      <ion-item  style="text-align: center;">
        <ion-label color="secondary">
          <strong>{{special.restaurant_name}} | {{special.name}}</strong>
          <p>{{voucher.expirationDate_unix_utc | daysLeft}}</p>
        </ion-label>
      </ion-item>
      <div id="redeem-button-container">
          <ion-button [disabled]="dateTimeService.isExpired(voucher.expirationDate_unix_utc) || isButtonLoading" expand="block" (click)="redeemVoucher()" >
            {{isButtonLoading? 'Waiting' : 'Redeem' }} &nbsp;
            <ion-icon *ngIf="!scanService.isNfcSupported && !isButtonLoading" name="qr-code-outline"></ion-icon>
            <ion-icon *ngIf="scanService.isNfcSupported && !isButtonLoading" size="large" src="assets/contactless-icon.svg"></ion-icon>
            <ion-spinner *ngIf="isButtonLoading" name="dots"></ion-spinner>
          </ion-button>
      </div>
  </div>
</div>

<div
style="height: 40vh; margin: 0.5em;"
mode="ios"
*ngIf="loading">
<ion-skeleton-text animated style="width: 100%;height: 100%;"></ion-skeleton-text>
</div>