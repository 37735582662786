import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { sendEmailVerification } from 'firebase/auth';
import { Deal, Shop } from 'src/app/models/interfaces';
import { RestaurantPage } from 'src/app/pages/restaurant/restaurant.page';
import { WalletPage } from 'src/app/pages/wallet/wallet.page';
import { AlertService } from 'src/app/services/alert.service';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { AuthService } from 'src/app/services/auth.service';
import { CloudfunctriggersService } from 'src/app/services/cloudfunctriggers.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { GisService } from 'src/app/services/gis.service';
import { HapticsService } from 'src/app/services/haptics.service';
import { LoyaltyCardService } from 'src/app/services/loyalty-card.service';
import { PushNotificationsService } from 'src/app/services/pushnotifications.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { TranslationService } from 'src/app/services/translation.service';
import { LoyaltyCardPreviewComponent } from 'src/app/shared/components/loyalty-card-preview/loyalty-card-preview.component';

@Component({
  selector: 'app-deal-lane-card',
  templateUrl: './deal-lane-card.component.html',
  styleUrls: ['./deal-lane-card.component.scss'],
})
export class DealLaneCardComponent implements OnInit {
  @Input() deal: Deal
  @Input() shop: Shop
  @Input() cardHeight = '50vh'
  @Input() mode: 'dealSelect' | 'loyaltyJoin' = 'loyaltyJoin';
  @Output() onDealSelectEmit: EventEmitter<Deal> = new EventEmitter();
  @Output() onWantsToFlip: EventEmitter<any> = new EventEmitter();
  isButtonLoading
  searchLocation
  constructor(
    private modalCtrl: ModalController,
    private hapticsService: HapticsService,
    private cloudTriggers: CloudfunctriggersService,
    private loyaltyCardService: LoyaltyCardService,
    private authService: AuthService,
    private toasts: ToastServiceService,
    public assetLinksService: AssetlinksService,
    private dateTimeService: DateTimeService,
    private alertService: AlertService,
    private pushNotificationsService: PushNotificationsService,
    private ts: TranslationService,
  ) {

  }

  ngOnInit() {

  }

  flip() {
    this.onWantsToFlip.emit()
  }

  async cardClick() {
    switch (this.mode) {
      case 'dealSelect':

        break;

      default:
        //  await this.viewRestaurant()
        break;
    }
  }

  private async registerClick() {
    await this.cloudTriggers.increaseSpecialClickCount(this.deal)
  }

  giveUserSpecialProgress() {
    try {
      let foundCard = this.loyaltyCardService.giveCardInWalletForThisSpecial(this.deal);
      // This is a dummy object when failed only intended for knowing when to display certain ui elements
      // TODO make more elegant
      return foundCard ? foundCard : {
        id: null,
        userId: this.deal.user_uid,
        complete: false,
        special_id: this.deal.id,
        stickers_count: null,
        restaurantId: this.deal.restaurant_id
      };
    } catch (error) {
      console.log(error);
    }
  }

  async actionButtonClick() {
    switch (this.mode) {
      case 'dealSelect':

        this.onDealSelectEmit.emit(this.deal)
        console.log('emitted from cardcomponent', this.deal);

        break;

      default:
        await this.JoinLoyaltyProgramme()
        break;
    }
  }

  async JoinLoyaltyProgramme() {
    try {
      if (this.isButtonLoading) {
        return
      }
      await this.hapticsService.hapticsImpactLight()
      let user = await this.authService.giveFirebaseAuth().currentUser
      if (!user.isAnonymous && !user.emailVerified) {
        await this.alertService.yumDealzAlertWithCustomButtons(
          '',
          `To participate in loyalty programs please verify your email by following the verification link sent to ${user.email || 'your email'}`,
          [
            {
              text: this.ts.getLocalizedValue('DEAL_LANE_CARD_COMPONENT.RESEND_LINK'),
              handler: () => {
                sendEmailVerification(user).then(async () => {
                  await this.toasts.showToast(`Link sent to ${user.email || 'your email'}`, '')
                }).catch(async () => {
                  await this.toasts.showToast(`Could not send link to ${user.email || 'your email'}`, '')
                })
              }
            },
            {
              text: this.ts.getLocalizedValue('DEAL_LANE_CARD_COMPONENT.EDIT_EMAIL'),
              handler: async () => {
                await this.authService.updateUserEmailAlert();
              }
            }
          ],
          true
        )
        return;
      }
      this.isButtonLoading = true;
      await this.loyaltyCardService.joinLoyalty(this.deal, user);
      this.isButtonLoading = false;
      this.askNotificationPermissions();
    } catch (error) {
      console.log(error);
      this.isButtonLoading = false;
      this.toasts.showErrorToast(error.message)
    }
  }

  private async askNotificationPermissions() {
    const notificationsEnabled = await this.pushNotificationsService.askForPermissions();
    if (notificationsEnabled) {
      try {
        this.pushNotificationsService.subscribeToShopTopic(this.deal.restaurant_id);
      } catch (error) {
        console.log(error);
      }
    }
  }

  async viewUserCard() {
    await this.hapticsService.hapticsImpactLight()
    const card = this.loyaltyCardService.giveCardInWalletForThisSpecial(this.deal)
    const modal = await this.modalCtrl.create({
      component: LoyaltyCardPreviewComponent,
     mode:'ios',
     initialBreakpoint: 1,
     breakpoints: [0,1],
     handle:false,
      componentProps:{
        loyaltyCard:card,
        deal:this.deal,
        shop:this.shop,
        mode:'previewWithScan',
        today:this.dateTimeService.giveLocalNowDay()
      }
    });
    await modal.present();
  }
}
