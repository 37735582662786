<ion-header class="ion-no-border">

  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-avatar >
        <img 
        [defaultImage]="assetLinksService.spinnerGif" 
        [lazyLoad]="shop.logo" 
        [errorImage]="assetLinksService.favicon"/>
      </ion-avatar>
    </ion-buttons>
    <ion-title>{{shop.name}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="return()" >
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  
  </ion-toolbar>
</ion-header>

<ion-content>
  <swiper 
    #swiper 
    direction="horizontal"
    [pagination]="{
      type: 'progressbar',
    }"
    class="ion-content-scroll-host"
    (swiper)="onSwiper($event)"
    (beforeTransitionStart)="onBeforeTransitionStart($event)">
  <ng-template swiperSlide *ngFor="let story of stories">
    <ion-card style="text-align: center;">
      <img 
      class="fade-in"
      style="border-radius: 8px;"
      [defaultImage]="assetLinksService.spinnerGif" 
      [lazyLoad]="story.picture" 
      [errorImage]="assetLinksService.favicon"/>
    </ion-card>
    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>{{story.expirationDateUnixUtc | daysLeft}}</ion-card-subtitle>
        <ion-card-title>{{story.name}}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        {{story.description}}
      </ion-card-content>
    </ion-card>
  </ng-template>
</swiper>
</ion-content>