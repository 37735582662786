<ion-header class="ion-no-border">

  <ion-toolbar>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-text style="text-align: center;">
    <h3 *ngIf="!isSafeToLogIn()">
      {{'LANDING_PAGE.SIGNUP_TO_SAVE_PROGRESS' | translate}}!
    </h3>
    <h3 *ngIf="isSafeToLogIn()">
      {{"LANDING_PAGE.LOGIN_TO_SAVE_PROGRESS" | translate}}!
    </h3>
  </ion-text>

  <ion-row class="ion-padding" *ngIf="!isSafeToLogIn()">
    <!-- <ion-col size="12">
      <ion-button  expand="block"  (click)="openSignUp()">
        Sign up
      </ion-button>
    </ion-col>
    <ion-col size="12">
      <ion-text style="text-align: center;">
        <p style="margin: 0;">{{'ALREADY_HAVE_AN_ACCOUNT' | translate }}?</p>
      </ion-text>
    </ion-col>
    <ion-col size="12">
      <ion-button [disabled]="!agreesToTerms" fill="outline" expand="block" (click)="openSignIn()">
        {{'LOGIN' | translate}}
      </ion-button>
    </ion-col> -->

    <ion-col size="12">
      <ion-button color="primary" expand="block" (click)="linkWithGoogle()">
        <ion-icon slot="start" name="logo-google"></ion-icon>
        {{"LANDING_PAGE.CONTINUE_WITH_GOOGLE" | translate}}
      </ion-button>
    </ion-col>
    <ion-col *ngIf="isIosPlatform" size="12">
      <ion-button color="dark" expand="block" (click)="linkWithApple()">
        <ion-icon slot="start" name="logo-apple"></ion-icon>
        {{"LANDING_PAGE.CONTINUE_WITH_APPLE" | translate}}
      </ion-button>
    </ion-col>
    <ion-col size="12">
      <ion-button color="secondary" expand="block" fill="clear" (click)="openSignUp()">
        <ion-icon slot="start" name="mail"></ion-icon>
        {{"LANDING_PAGE.CONTINUE_WITH_EMAIL" | translate}}
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-row class="ion-padding" *ngIf="isSafeToLogIn()">
    <ion-col size="12">
      <ion-button color="primary" expand="block" (click)="googleSignIn()">
        <ion-icon slot="start" name="logo-google"></ion-icon>
        {{"LANDING_PAGE.CONTINUE_WITH_GOOGLE" | translate}}
      </ion-button>
    </ion-col>
    <ion-col *ngIf="isIosPlatform" size="12">
      <ion-button color="dark" expand="block" (click)="appleSignIn()">
        <ion-icon slot="start" name="logo-apple"></ion-icon>
        {{"LANDING_PAGE.CONTINUE_WITH_APPLE" | translate}}
      </ion-button>
    </ion-col>
    <ion-col size="12">
      <ion-button color="secondary" expand="block" fill="clear" (click)="openSignIn()">
        <ion-icon slot="start" name="mail"></ion-icon>
        {{"LANDING_PAGE.CONTINUE_WITH_EMAIL" | translate}}
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="12">
      <ion-button color="light" expand="block" (click)="close()">
        <u style="color:gray;"> {{"LANDING_PAGE.SKIP_FOR_NOW" | translate}}</u>
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-footer>
    <app-legal-footer-links [color]="'dark'"></app-legal-footer-links>
  </ion-footer>
</ion-content>