import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { ForgotPwPage } from '../forgot-pw/forgot-pw.page';
import { RegexpatternsService } from 'src/app/services/regexpatterns.service';
import { User } from 'firebase/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Capacitor } from '@capacitor/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  email
  pw
  loginForm: FormGroup;
  isIosPlatform: boolean = false;
  constructor(
    private auth: AuthService,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private toasts: ToastServiceService,
    public assetLinksService: AssetlinksService,
    private ts: TranslationService,
  ) { }

  ngOnInit() {
    this.isIosPlatform = Capacitor.getPlatform() == 'ios'
    this.loginForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(RegexpatternsService.getEmailValidatorPattern())]
      ],
      pw: ['', [Validators.required,
      Validators.maxLength(50)]]
    });
  }

  async forgotPw() {
    try {
      const modal = await this.modalCtrl.create({
        component: ForgotPwPage
      });
      await modal.present();
    } catch (error) {
      this.toasts.showErrorToast(this.ts.getLocalizedValue('ERRORS.TRIED_TO_OPEN_FORGOT_PASSWORD_PAGE_BUT_FAILED'))
    }
  }

  async signIn() {
    try {
      await this.loadingService.showOrContinueShowingLoading();
      let { email, pw } = this.loginForm.value;
      email = email.trim();
      this.auth.emailSignIn(email, pw).then(async (cred) => {
        await this.loadingService.hideLoading();
        this.done(cred.user);
      },
        async err => {
          await this.loadingService.hideLoading();
          this.auth.handleAuthErrors(err);
        })
    } catch (error) {
      console.log(error);
      await this.loadingService.hideLoading();
      this.toasts.showErrorToast(error.message)
    }
  }

  async goToSignUp() {
    await this.modalCtrl.dismiss({ wantsToSignUp: true });
  }

  // async googleSignIn(){
  //   try {
  //     await this.loadingService.showOrContinueShowingLoading();
  //     this.auth.googleSignIn().then(async (cred)=>{
  //       await this.loadingService.hideLoading();
  //       this.done(cred.user)
  //     }, 
  //     async err=>{
  //       await this.loadingService.hideLoading();
  //       this.auth.handleAuthErrors(err)
  //     })
  //   } catch (error) {
  //     console.log(error);
  //     await this.loadingService.hideLoading();
  //     this.toasts.showErrorToast(error.message);
  //   }
  // }

  // async appleSignIn(){
  //   try {
  //     await this.loadingService.showOrContinueShowingLoading();
  //     this.auth.appleSignIn().then(async (cred)=>{
  //       await this.loadingService.hideLoading();
  //       this.done(cred.user)
  //     }, 
  //     async err=>{
  //       await this.loadingService.hideLoading();
  //       this.auth.handleAuthErrors(err)
  //     })
  //   } catch (error) {
  //     console.log(error);
  //     await this.loadingService.hideLoading();
  //     this.toasts.showErrorToast(error.message);
  //   }
  // }

  async done(fbUser: User) {
    try {
      await this.modalCtrl.dismiss({ loggedIn: true, fbUser });
    } catch (error) {
      console.log(error);
      this.toasts.showErrorToast(this.ts.getLocalizedValue('ERRORS.TRIED_TO_CLOSE_PAGE_AFTER_LOGIN_BUT_FAILED'))
    }
  }

  async cancel() {
    try {
      await this.modalCtrl.dismiss({ loggedIn: false });
    } catch (error) {
      console.log(error);
      this.toasts.showErrorToast(this.ts.getLocalizedValue('ERRORS.TRIED_TO_CLOSE_THE_PAGE_WITHOUT_LOGIN_BUT_FAILED'));
    }
  }

}
