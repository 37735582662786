import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-camera-disabled-stamp',
  templateUrl: './camera-disabled-stamp.component.html',
  styleUrls: ['./camera-disabled-stamp.component.scss'],
})
export class CameraDisabledStampComponent  implements OnInit {
  @Input() mode:'button'
  constructor() { }

  ngOnInit() {}

}
