import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LandingPage } from 'src/app/pages/landing/landing.page';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login-banner',
  templateUrl: './login-banner.component.html',
  styleUrls: ['./login-banner.component.scss'],
})
export class LoginBannerComponent  implements OnInit {
  @Input() presentingElement:HTMLIonRouterOutletElement;
  constructor(
    private modalCtrl:ModalController
  ) { }

  ngOnInit() {}

  async showIntro(){
    const modal = await this.modalCtrl.create({
      component: LandingPage,
      mode:'ios',
      initialBreakpoint:0.7,
      breakpoints:[0,0.7],
      handle:false,
    });
    await modal.present();
  }

}
