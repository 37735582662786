<ion-grid fixed>
  <ion-row>
    <ion-col size="12" class="ion-text-center">
      <ion-text [color]="color" style="font-size: 0.7em;">
        {{'LEGAL_FOOTER_COMPONENT.BY_USING_THE_APP' | translate }} <a href="#"
          (click)="openTerms($event)">{{'LEGAL_FOOTER_COMPONENT.TERMS_OF_SERVICE' |
          translate}}</a> {{'AND' | translate}} <a href="#"
          (click)="openPrivacy($event)">{{'LEGAL_FOOTER_COMPONENT.PRIVACY_POLICY' |
          translate}}y</a>.
      </ion-text>
    </ion-col>
  </ion-row>
</ion-grid>