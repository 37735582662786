import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PrivacyPolicyPage } from 'src/app/pages/info/modals/privacy-policy/privacy-policy.page';
import { TermsOfServicePage } from 'src/app/pages/info/modals/terms-of-service/terms-of-service.page';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-legal-footer-links',
  templateUrl: './legal-footer-links.component.html',
  styleUrls: ['./legal-footer-links.component.scss'],
})
export class LegalFooterLinksComponent implements OnInit {
  @Input() color: 'medium' | 'light' | 'dark'
  constructor(
    private modalCtrl: ModalController,
    private toasts: ToastServiceService,
    private ts: TranslationService,
  ) { }

  ngOnInit() { }

  async openTerms(e) {
    try {
      e.preventDefault();
      let modal = await this.modalCtrl.create({
        component: TermsOfServicePage,
        componentProps: {
          isModal: true
        }
      })
      await modal.present()
    } catch (error) {
      console.log(error);
      this.toasts.showErrorToast(this.ts.getLocalizedValue('LEGAL_FOOTER_COMPONENT.TRIED_TO_OPEN_INFO_PAGE_BUT_COULD_NOT'))
    }
  }

  async openPrivacy(e) {
    e.preventDefault();
    let modal = await this.modalCtrl.create({
      component: PrivacyPolicyPage,
      componentProps: {
        isModal: true
      }
    })
    await modal.present()
  }

}
