<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-avatar>
        <img [defaultImage]="assetLinksService.spinnerGif" [lazyLoad]="shop.logo"
          [errorImage]="assetLinksService.favicon" />
      </ion-avatar>
    </ion-buttons>
    <ion-title>{{'LOYALTY_CARD_PREVIEW_COMPONENT.YOUR_CARD' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button [disabled]="showScanInstructions" (click)="return()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- <ion-text *ngIf="showScanInstructions " color="dark">
    <p>Tap and hold the back top of your device against the YumDealz tag.</p>
  </ion-text> -->
  <app-wallet-loyalty-card id="previewCard" *ngIf="loyaltyCard" [loyaltyCard]="loyaltyCard" [inMemorySpecial]="deal"
    [cardHeight]="cardHeight" [mode]="'cardPreview'" [shop]="shop"></app-wallet-loyalty-card>



</ion-content>
<ion-footer *ngIf="mode == 'previewWithScan'" lass="ion-no-border ion-no-padding">
  <ion-toolbar>
        <ion-button [disabled]="showScanInstructions || loading" mode="ios" (click)="scan()" expand="block" shape="round">
          <ion-icon *ngIf="(!showScanInstructions && !loading) && scanService.isNfcSupported" slot="start" name="radio"></ion-icon>
          <ion-icon *ngIf="(!showScanInstructions && !loading) && !scanService.isNfcSupported" slot="start" name="qr-code"></ion-icon>
          <ion-spinner *ngIf="showScanInstructions || loading" name="dots"></ion-spinner> &nbsp;
          <!-- TODO translate these -->
          {{showScanInstructions ? 'Scanning' : loading ? 'Loading' : loyaltyCard.stickers_count == 0 ? 'Get a stamp' : 'Get another stamp'}}
        </ion-button>
  </ion-toolbar>
</ion-footer>