import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-dealz-around-you-stamp',
  templateUrl: './show-dealz-around-you.component.html',
  styleUrls: ['./show-dealz-around-you.component.scss'],
})
export class ShowDealzAroundYouStampComponent  implements OnInit {
  @Input() mode:'button'
  constructor() { }

  ngOnInit() {}

}
