import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-click-to-scan-nfc-stamp',
  templateUrl: './click-to-scan-nfc-stamp.component.html',
  styleUrls: ['./click-to-scan-nfc-stamp.component.scss'],
})
export class ClickToScanNfcStampComponent  implements OnInit {
  @Input() mode:'button'
  @Input() behavior:'static'|'animate'
  constructor() { }

  ngOnInit() {}

}
