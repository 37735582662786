


  <ion-card mode="ios" [style.height]="cardHeight" class="card">
    <div 
      id="flip-button"
      (click)="flipBack()"
      >
    <div id="flip-icon">
      <ion-icon name="return-up-back"></ion-icon>
      <br>
      <ion-icon name="return-down-forward"></ion-icon>
    </div>
  </div>
    <ion-card-header>
      <ion-card-subtitle color="light-shade">{{shop.name}}</ion-card-subtitle>
      <ion-card-title color="light">{{deal.name}}</ion-card-title>
    </ion-card-header>
    <ion-grid fixed id="main-grid">
      <ion-row id="main-row">
        <ion-col id="main-col">
          <div id="img-container" class="fade-in">
            <img  
            [style.height]="cardHeight"
            id="background-image"
            [defaultImage]="assetLinksService.spinnerGif" 
            [lazyLoad]="shop.logo" 
            [errorImage]="assetLinksService.favicon"
            onerror="this.src='assets/favicon.png'">
          </div>
        </ion-col>
      </ion-row>
      <div id="stamps-container">
        <ion-row>
          <ion-col class="ion-text-centre" [id]="'nextStampSlot_'+in" *ngFor="let in of arrayMaker(deal.stamps_required)" size="2">
            <img [src]='assetLinksService.stampHolder'>
          </ion-col>
        </ion-row>
        <!-- <ion-fab edge="true" vertical="top" horizontal="end" shape="round" slot="fixed">
          <ion-fab-button [disabled]="isButtonLoading"  (click)="actionButtonClick()"  *ngIf="giveUserSpecialProgress().stickers_count==null"    >
            <ion-icon *ngIf="!isButtonLoading"  name="add"></ion-icon>
            <ion-spinner *ngIf="isButtonLoading" name="bubbles"></ion-spinner>
          </ion-fab-button>
          <ion-fab-button (click)="viewUserCard()" color="success"  *ngIf="giveUserSpecialProgress().stickers_count>0 || giveUserSpecialProgress().stickers_count===0"  [disabled]="isButtonLoading"  >
           <ion-icon color="light" name="wallet"></ion-icon>
          </ion-fab-button>
        </ion-fab> -->
      </div>
    </ion-grid>
  </ion-card>

