import { Injectable } from '@angular/core';
import {
  addDoc, collectionGroup,
  CollectionReference,
  deleteDoc,
  doc, DocumentData, DocumentReference, DocumentSnapshot, endAt, Firestore,
  getDoc, getDocs, onSnapshot, orderBy, query, runTransaction, setDoc, startAt, Transaction, updateDoc, where
} from 'firebase/firestore';
import { collection, Query, Timestamp } from 'firebase/firestore';
import { FIRESTORE_ROOT_COLLECTIONS, FIRESTORE_SUB_COLLECTIONS } from '../models/enums';
import * as geofire from 'geofire-common'
import { PushTokenDoc, Shop, RestaurantIdAndName, Deal, Story, Voucher, WalletLoyaltyCard, YumDealzUser, Stamp } from '../models/interfaces';
import { ToastServiceService } from './toast-service.service';
import { Unsubscribe, User } from 'firebase/auth';
import { PreferencesService } from './preferences.service';
import { UserIdService } from './user-id.service';
import { environment } from '../../environments/environment';
import { DateTimeService } from './date-time.service';
import { FirebaseService } from './firebase.service';
import { BehaviorSubject } from 'rxjs';
import { GisService } from './gis.service';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private yumDealzUserDocRef: DocumentReference<DocumentData>
  private merchantUserDocRef: DocumentReference<DocumentData>
  private userAllLoyaltyCardsRef: CollectionReference<DocumentData>
  private pushTokensCollectionRef: CollectionReference<DocumentData>
  private yumDealzUserPushTokensRef: Query<DocumentData>
  private userIncompleteLoyaltyCardsRef: Query<DocumentData>
  private allDealzCollectionRef: Query<DocumentData>
  private allStoryDealzCollectionRef: Query<DocumentData>
  private activeDealzCollectionRef: Query<DocumentData>
  private activeStoryDealzCollectionRef: Query<DocumentData>
  private restaurantsCollectionRef: Query<DocumentData>
  private stampsCollectionRef: Query<DocumentData>
  private vouchersCollectionRef: Query<DocumentData>
  private userVouchersRef: Query<DocumentData>
  private userUid: string;
  private firestore: Firestore;
  private listenerUnsubscribes: {
    userVouchers: Unsubscribe,
    userLoyaltyCards: Unsubscribe,
    browsingShops: Unsubscribe
  } = {
      userVouchers: null,
      userLoyaltyCards: null,
      browsingShops: null
    }
  constructor(
    private firebaseService: FirebaseService,
    private userIdService: UserIdService,
    private toastsService: ToastServiceService,
    private preferences: PreferencesService,
    private dateTimeService: DateTimeService,
    private gisService: GisService,
    private ts: TranslationService
  ) {
    this.userIdService.userId$.subscribe((userId) => {
      if (!userId) return;
      this.firestore = this.firebaseService.getDb();
      this.userUid = userId;
      this.pushTokensCollectionRef = collection(this.firestore, FIRESTORE_ROOT_COLLECTIONS.PUSH_TOKENS);
      this.yumDealzUserPushTokensRef = query(this.pushTokensCollectionRef, where('userId', '==', this.userUid));
      this.yumDealzUserDocRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.YUM_DEALS_USERS}/${userId}`);
      this.merchantUserDocRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.BUSINESS_OWNERS}/${userId}`);
      this.userAllLoyaltyCardsRef = collection(this.firestore, `${this.yumDealzUserDocRef.path}/${FIRESTORE_SUB_COLLECTIONS.WALLET_LOYALTY_CARDS}`);
      this.userIncompleteLoyaltyCardsRef = query(this.userAllLoyaltyCardsRef, where('complete', '==', false));
      this.restaurantsCollectionRef = collectionGroup(this.firestore, FIRESTORE_SUB_COLLECTIONS.RESTAURANTS);
      this.stampsCollectionRef = collectionGroup(this.firestore, FIRESTORE_SUB_COLLECTIONS.STAMPS);
      this.allDealzCollectionRef = collectionGroup(this.firestore, FIRESTORE_SUB_COLLECTIONS.SPECIALS);
      this.activeDealzCollectionRef = query(this.allDealzCollectionRef, where('active', '==', true));
      this.allStoryDealzCollectionRef = collectionGroup(this.firestore, FIRESTORE_SUB_COLLECTIONS.STORIES);
      this.activeStoryDealzCollectionRef = query(this.allStoryDealzCollectionRef, where('expirationDateUnixUtc', '>', Date.now()));
      this.vouchersCollectionRef = collectionGroup(this.firestore, FIRESTORE_SUB_COLLECTIONS.VOUCHERS);
      this.userVouchersRef = query(this.vouchersCollectionRef, where('claimerUId', '==', userId), where('redeemed', '==', false));
    });
  }

  /**
   * only need to call this once.
   * If called more than once it will cancel the previous 
   * listener and replace it with a new one
   * @param subject 
   */
  listenToUserVouchers(subject: BehaviorSubject<Voucher[]>) {
    const refq = query(this.userVouchersRef, where('expirationDate_unix_utc', '>', Date.now()))
    if (this.listenerUnsubscribes.userVouchers) {
      this.listenerUnsubscribes.userVouchers();
    }
    this.listenerUnsubscribes.userVouchers = onSnapshot(refq, (querySnapshot) => {
      const vouchers: Voucher[] = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          vouchers.push(doc.data() as Voucher);
        }
      });
      subject.next(vouchers);
    });
  }


  /**
   * only need to call this once.
   * If called more than once it will cancel the previous 
   * listener and replace it with a new one
   * @param subject 
   */
  listenToYumDealzUserIncompleteLoyaltyCards(subject: BehaviorSubject<WalletLoyaltyCard[]>) {
    const refq = query(this.userIncompleteLoyaltyCardsRef, where('expiryDate', '>', this.dateTimeService.todayNowUtcUnixMilisecs()))
    if (this.listenerUnsubscribes.userLoyaltyCards) {
      this.listenerUnsubscribes.userLoyaltyCards();
    }
    this.listenerUnsubscribes.userLoyaltyCards = onSnapshot(refq, (querySnapshot) => {
      const walletLoyaltyCards: WalletLoyaltyCard[] = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          walletLoyaltyCards.push(doc.data() as WalletLoyaltyCard);
        }
      });
      subject.next(walletLoyaltyCards);
    });
  }


  private async addPushToken(token: string, notificationsEnabled: boolean) {
    const data: PushTokenDoc = {
      token,
      userId: this.userUid,
      timestamp: Timestamp.fromDate(new Date()),
      notificationsEnabled
    }
    await addDoc(this.pushTokensCollectionRef, data)
  }

  private async refreshPushToken(existingTokenDoc: DocumentReference<DocumentData>, notificationsEnabled: boolean) {
    const data = {
      timestamp: Timestamp.fromDate(new Date()),
      notificationsEnabled
    }
    await updateDoc(existingTokenDoc, data);
  }

  async refreshOrAddPushToken(token: string, notificationsEnabled: boolean) {
    let snapShot = await getDocs(this.yumDealzUserPushTokensRef);
    let existingTokenDoc: DocumentReference<DocumentData>;
    snapShot.docs.forEach(doc => {
      let tokenDoc = doc.data() as PushTokenDoc;
      if (tokenDoc.token == token) {
        existingTokenDoc = doc.ref
      }
    })

    if (existingTokenDoc) {
      await this.refreshPushToken(existingTokenDoc, notificationsEnabled)
    } else {
      await this.addPushToken(token, notificationsEnabled);
    }
  }

  async rmPushToken(token: string) {
    let snapShot = await getDocs(this.yumDealzUserPushTokensRef);
    let docToDeleteRef
    snapShot.docs.forEach(doc => {
      if (doc.exists()) {
        let tokenDoc = doc.data() as PushTokenDoc;
        if (tokenDoc.token == token) {
          docToDeleteRef = doc.ref
        }
      }
    })
    if (docToDeleteRef) {
      return deleteDoc(docToDeleteRef);
    }
  }

  async fetchRestaurantOwnerDocData(resUserId) {
    let document = await getDoc(doc(this.firestore, `users/${resUserId}`))
    return document.data()
  }

  async getShopActiveStories(shopId: string) {
    const storiesSnapShot = await getDocs(
      query(this.activeStoryDealzCollectionRef, where('shopId', '==', shopId))
    );
    const shopStories: Story[] = [];
    for (const doc of storiesSnapShot.docs) {
      const story = this.giveStoryObjectFromFirebaseStoryDoc(doc)
      shopStories.push(story)
    }
    return shopStories;
  }

  async getShopActiveDealz(restaurant: RestaurantIdAndName) {
    const specialsSnapShot = await getDocs(
      query(this.activeDealzCollectionRef, where('restaurant_id', '==', restaurant.id))
    );
    const shopSpecials: Deal[] = [];
    for (const doc of specialsSnapShot.docs) {
      const special = this.giveSpecialObjectFromFirebaseSpecialDoc(doc)
      shopSpecials.push(special)
    }
    return shopSpecials;
  }

  async minusOneFromShopOwnerCardCount(restaurantOwnerUserDoc) {
    const data = { cardsAmount: restaurantOwnerUserDoc.cardsAmount - 1 }
    let ref = doc(this.firestore, `users/${restaurantOwnerUserDoc.uid}`)
    await updateDoc(ref, data)
  }

  async newLoyaltyCard(businessOwnerId: string, restaurantId: string, special_id: string, specialExpiresAfter: number) {
    let date = new Date();
    let thisDay = date.getUTCDate();
    let thisYear = date.getUTCFullYear();
    let thisMonth = date.getUTCMonth();
    let utc_end_of_day_unix = Date.UTC(thisYear, thisMonth, thisDay, 23, 59, 59, 999)
    let expiryDate = utc_end_of_day_unix + specialExpiresAfter
    let date_added_unix_utc_timestamp = this.dateTimeService.todayNowUtcUnixMilisecs();
    let collectionRef = collection(this.firestore, `${this.yumDealzUserDocRef.path}/${FIRESTORE_SUB_COLLECTIONS.WALLET_LOYALTY_CARDS}`)
    const docRef = doc(collectionRef)
    let card: WalletLoyaltyCard = {
      id: docRef.id,
      userId: businessOwnerId,
      restaurantId: restaurantId,
      complete: false,
      special_id: special_id,
      expiryDate,
      date_added_unix_utc_timestamp,
      stickers_count: 0
    }
    await setDoc(docRef, card)
  }

  async fetchUserLoyaltyCardDocData(cardId) {
    let document = await getDoc(doc(this.firestore, `${this.yumDealzUserDocRef.path}/${FIRESTORE_SUB_COLLECTIONS.WALLET_LOYALTY_CARDS}/${cardId}`))
    return document.data()
  }


  claimVoucher(voucher: Voucher, endOfdayUtcUnix: number) {
    let expirationDate = endOfdayUtcUnix + (1000 * 60 * 60 * 24 * 14);
    const voucherDocRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.BUSINESS_OWNERS}/${voucher.userId}/${FIRESTORE_SUB_COLLECTIONS.RESTAURANTS}/${voucher.restaurantUid}/${FIRESTORE_SUB_COLLECTIONS.SPECIALS}/${voucher.specialUid}/${FIRESTORE_SUB_COLLECTIONS.VOUCHERS}/${voucher.uid}`)
    return updateDoc(voucherDocRef, {
      claimed: true,
      claimerUId: this.userUid,
      expirationDate_unix_utc: expirationDate
    })
  }


  async getStampTransaction(special: Deal, loyaltyCard: WalletLoyaltyCard, lat: number, lng: number) {
    const docUpdates = []
    let newCount: number = null;

    loyaltyCard.stickers_count < (special.stamps_required - 1) ?
      newCount = loyaltyCard.stickers_count + 1 :
      newCount = null;

    const loyaltyCardUpdate = async (transaction: Transaction) => {
      const data = { stickers_count: newCount, complete: newCount ? false : true }
      let ref = doc(this.firestore, `${this.yumDealzUserDocRef.path}/${FIRESTORE_SUB_COLLECTIONS.WALLET_LOYALTY_CARDS}/${loyaltyCard.id}`)
      await transaction.update(ref, data);
    }

    const giveStamp = async (transaction: Transaction) => {
      const data = {
        lat,
        lng,
        // sometimes location is on and granted but gps was slow to respond. for UX we rather not wait.. but ugh...
        geohash: lat && lng ? this.gisService.makeGeoHashFromLatLng({ lat, lng }) : null,
        timestamp_unix_utc: this.dateTimeService.todayNowUtcUnixMilisecs(),
        card_id: loyaltyCard.id,
        deal_id: special.id,
        shop_id: special.restaurant_id,
        shop_user_id: special.user_uid,
        user_id: this.userUid,
      } as Stamp
      let ref = doc(collection(this.firestore, `${this.yumDealzUserDocRef.path}/${FIRESTORE_SUB_COLLECTIONS.WALLET_LOYALTY_CARDS}/${loyaltyCard.id}/${FIRESTORE_SUB_COLLECTIONS.STAMPS}`))
      await transaction.set(ref, data);
    }

    const voucherUpdate = async (transaction: Transaction) => {
      let date = new Date();
      let thisDay = date.getUTCDate();
      let thisYear = date.getUTCFullYear();
      let thisMonth = date.getUTCMonth();
      let utc_start_of_day_unix = Date.UTC(thisYear, thisMonth, thisDay, 0, 0, 0, 0);
      let utc_end_of_day_unix = Date.UTC(thisYear, thisMonth, thisDay, 23, 59, 59, 999);
      let expirationDate_unix_utc = utc_end_of_day_unix + special.voucherExpiresAfter;
      let collectionRef = collection(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.BUSINESS_OWNERS}/${special.user_uid}/${FIRESTORE_SUB_COLLECTIONS.RESTAURANTS}/${special.restaurant_id}/${FIRESTORE_SUB_COLLECTIONS.SPECIALS}/${special.id}/${FIRESTORE_SUB_COLLECTIONS.VOUCHERS}`)
      const docRef = doc(collectionRef)
      let voucher = {
        uid: docRef.id,
        claimerUId: this.userUid,
        specialLat: special.lat,
        restaurantUid: special.restaurant_id,
        userId: special.user_uid,
        redeemed: false,
        notificationRadius: 0,
        specialLng: special.lng,
        specialUid: special.id,
        activationDate: {
          dayStart_unix_utx: utc_start_of_day_unix,
          dayEnd_unix_utx: utc_end_of_day_unix
        },
        claimed: true,
        expirationDate_unix_utc: expirationDate_unix_utc
      }
      await transaction.set(docRef, voucher);
    }

    docUpdates.push(loyaltyCardUpdate);
    docUpdates.push(giveStamp);

    if (newCount == null) {
      docUpdates.push(voucherUpdate)
    }


    return runTransaction(this.firestore, async (transaction) => {
      try {
        // Perform your transactional operations
        for (const docUpdate of docUpdates) {
          await docUpdate(transaction);
        }
        return newCount
        // If all operations are successful, the transaction will be committed
      } catch (error) {
        // Handle transaction failure or specific error cases
        await this.toastError(error);
        throw error;
      }
    })
  }

  async redeemVoucher(voucher: Voucher, redeemedOnUnix: number) {
    const voucherUpdate = async (transaction: Transaction) => {
      const data = { redeemed: true, redeemed_on: redeemedOnUnix }
      const voucherDocRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.BUSINESS_OWNERS}/${voucher.userId}/${FIRESTORE_SUB_COLLECTIONS.RESTAURANTS}/${voucher.restaurantUid}/${FIRESTORE_SUB_COLLECTIONS.SPECIALS}/${voucher.specialUid}/${FIRESTORE_SUB_COLLECTIONS.VOUCHERS}/${voucher.uid}`)
      await transaction.update(voucherDocRef, data);
    }

    return runTransaction(this.firestore, async (transaction) => {
      try {
        await voucherUpdate(transaction)
        // If all operations are successful, the transaction will be committed
      } catch (error) {
        // Handle transaction failure or specific error cases
        await this.toastError(error);
        throw error;
      }
    })
  }

  async initUser(firebaseUser: User) {
    let doc = await getDoc(this.yumDealzUserDocRef)
    let potentialUser = doc.data() as YumDealzUser
    if (!potentialUser) {
      let data = this.giveNewUserObjectFromFirebaseUser(firebaseUser);
      await setDoc(this.yumDealzUserDocRef, data);
      let newUser = { ...data }
      await this.preferences.saveUserIdAndEmail(newUser.uniqueDeviceId, newUser.email);
      return newUser
    }
    await this.preferences.saveUserIdAndEmail(potentialUser.uniqueDeviceId, potentialUser.email)
    let existingUser: YumDealzUser = {
      uniqueDeviceId: potentialUser.uniqueDeviceId,
      displayName: firebaseUser.displayName,
      email: firebaseUser.email,
      emailVerified: firebaseUser.emailVerified,
      providerId: firebaseUser.providerId,
      phoneNumber: firebaseUser.phoneNumber,
      photoURL: firebaseUser.photoURL,
      likedSpecials: potentialUser.likedSpecials,
      pushToken: potentialUser.pushToken,
      lastSearchLocation: potentialUser.lastSearchLocation ? potentialUser.lastSearchLocation : null,
      notificationArea: potentialUser.notificationArea ? potentialUser.notificationArea : {
        geohash: '',
        radius: 5,
        lat: 0,
        lng: 0,
      },
      lastTimeVisited: Timestamp.fromDate(new Date()),
      installedVersion: environment.appVersion
    }
    let user: YumDealzUser = existingUser
    // await this.editUser(user)
    this.editUser(user)
    return user as YumDealzUser
  }

  async editUser(data: YumDealzUser) {
    await updateDoc(this.yumDealzUserDocRef, data as DocumentData);
  }

  async deleteUser() {
    await updateDoc(this.yumDealzUserDocRef, { markedForDeletion: true } as DocumentData);
  }

  async fetchPossibleMerchantUser() {
    return await getDoc(this.merchantUserDocRef);
  }

  async refreshUser() {
    let user = await getDoc(this.yumDealzUserDocRef)
    if (user.exists) {
      return user.data() as YumDealzUser
    }
  }

  async fetchYumDealzUser(userId?: string) {
    if (userId) {
      let docRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.YUM_DEALS_USERS}/${userId}`)
      return getDoc(docRef);
    } else {
      return getDoc(this.yumDealzUserDocRef);
    }
  }




  async fetchYumDealzUserVouchers() {
    return getDocs(this.userVouchersRef);
  }


  fetchAllShopsAtLocation(location: [number, number], radiusInM: number) {
    let baseQuery = query(this.restaurantsCollectionRef);
    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geofire.geohashQueryBounds(location, radiusInM);
    const promises = [];

    for (const b of bounds) {
      const geoQuery = query(
        baseQuery,
        orderBy('geohash'),
        startAt(b[0]), endAt(b[1])
      )
      promises.push(getDocs(geoQuery));
    }

    // Collect all the query results together into a single list
    return Promise.all(promises).then((snapshots) => {
      const matchingDocs: Shop[] = [];
      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const lat = doc.get('lat');
          const lng = doc.get('lng');
          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = geofire.distanceBetween([lat, lng], location);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            let shop = this.giveShopObjectFromFirebaseShopDoc(doc)
            matchingDocs.push(shop);
          }
        }
      }

      return matchingDocs
    })
  }

  async fetchaSpecial(shopOwnerId: string, restaurantId: string, specialId: string) {
    const docRef = doc(this.firestore, `${FIRESTORE_ROOT_COLLECTIONS.BUSINESS_OWNERS}/${shopOwnerId}/${FIRESTORE_SUB_COLLECTIONS.RESTAURANTS}/${restaurantId}/${FIRESTORE_SUB_COLLECTIONS.SPECIALS}/${specialId}`)
    const specialDoc = await getDoc(docRef)
    const special = this.giveSpecialObjectFromFirebaseSpecialDoc(specialDoc)
    return special;
  }

  async getRestaurantWithShopId(shopId: string) {
    let storedRestaurant = await this.preferences.retrieveRestaurantFromStorage(shopId);
    // check if stored restaurant
    let revision = null;
    if (storedRestaurant) {
      revision = storedRestaurant.revision
    }

    let resRef = null;
    // if restaurant is stored
    // revision will be set 
    if (revision) {
      resRef = query(this.restaurantsCollectionRef, where('id', '==', shopId), where('revision', '>', revision))
    } else {
      resRef = query(this.restaurantsCollectionRef, where('id', '==', shopId))
    }

    const restaurants = await this.downloadRestaurantsRef(resRef);

    if (restaurants.length > 0) {
      // downloaded a restaurant
      let newRestaurant = restaurants[0];
      await this.preferences.saveRestaurantInStorage(newRestaurant);
      return newRestaurant
    } else if (revision) {
      // did not download but there is local copy of it.
      // this. means upstream revision is not greater than
      // local revision
      return storedRestaurant
    } else {
      // found nothing but there is also no local copy of it
      return null;
    }
  }

  async getShopWithShopUserId(shopUserId: string) {
    let shopsRef = query(this.restaurantsCollectionRef, where('user_uid', '==', shopUserId))
    const shops = await this.downloadRestaurantsRef(shopsRef);
    if (shops.length > 0) {
      let shop = shops[0]; // TODO this will not work for users with multiple shops
      return shop
    } else {
      // found nothing but there is also no local copy of it
      return null;
    }
  }

  private async downloadRestaurantsRef(ref: Query<DocumentData>) {
    const restaurantResponse = await getDocs(ref)
    let restaurants: Shop[] = [];
    let docs = restaurantResponse.docs;
    docs.forEach((doc) => {
      restaurants.push(this.giveShopObjectFromFirebaseShopDoc(doc));
    });
    return restaurants;
  }

  private giveNewUserObjectFromFirebaseUser(user) {
    return {
      uniqueDeviceId: user.uid,
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      providerId: user.providerId,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      likedSpecials: [],
      pushToken: null,
      notificationArea: {
        geohash: '',
        radius: 5,
        lat: 0,
        lng: 0,
      },
      lastTimeVisited: Timestamp.fromDate(new Date()),
      installedVersion: environment.appVersion
    } as YumDealzUser
  }

  private giveSpecialObjectFromFirebaseSpecialDoc(specialDoc: DocumentSnapshot<DocumentData>) {
    const data = specialDoc.data() as Deal
    const special: Deal = {
      name: data['name'],
      description: data['description'],
      price_now: data['price_now'],
      price_was: data['price_was'],
      descriptive_tag: data['descriptive_tag'],
      active: data['active'],
      expiresAfter: data['expiresAfter'],
      voucherExpiresAfter: data.voucherExpiresAfter || (1000 * 60 * 60 * 24 * 14),
      restaurant_logo: data['restaurant_logo'],
      picture: data['picture'],
      likes: data['likes'],
      days: data['days'],
      stamps_required: data.stamps_required || 10,
      saturday_trading_from: data.saturday_trading_from,
      saturday_trading_to: data.saturday_trading_to,
      sunday_trading_from: data.sunday_trading_from,
      sunday_trading_to: data.sunday_trading_to,
      trading_from: data.trading_from,
      trading_to: data.trading_to,
      lat: data['lat'],
      lng: data['lng'],
      geohash: data['geohash'],
      id: specialDoc.id,
      clicks: data['clicks'],
      user_uid: data['user_uid'],
      restaurant_id: data['restaurant_id'],
      restaurant_name: data['restaurant_name'],
      type: data['type'],
    };
    return special
  }

  private giveShopObjectFromFirebaseShopDoc(shopDoc: DocumentSnapshot<DocumentData>) {
    const data = shopDoc.data() as Shop
    let shopData: Shop = {
      name: data['name'],
      lat: data['lat'],
      lng: data['lng'],
      geohash: data['geohash'],
      whatsapp: data['whatsapp'],
      facebook: data['facebook'],
      revision: data['revision'],
      instagram: data['instagram'],
      website: data['website'],
      logo: data['logo'],
      holiday_trading_from: data['holiday_trading_from'],
      holiday_trading_to: data['holiday_trading_to'],
      saturday_trading_to: data['saturday_trading_to'],
      saturday_trading_from: data['saturday_trading_from'],
      sunday_trading_to: data['sunday_trading_to'],
      sunday_trading_from: data['sunday_trading_from'],
      trading_to: data['trading_to'],
      trading_from: data['trading_from'],
      normal_trading_days: data['normal_trading_days'],
      id: data['id'],
      contact_number: data['contact_number'],
      address: data['address'],
      email: data['email'],
      verified: data['verified'],
      user_uid: data['user_uid'],
      story_active: data['story_active'] ? data['story_active'] : false
    }
    return shopData
  }

  private giveStoryObjectFromFirebaseStoryDoc(storyDoc: DocumentSnapshot<DocumentData>) {
    const data = storyDoc.data() as Story
    const story: Story = {
      name: data.name,
      description: data.description,
      id: storyDoc.id,
      clicks: data.clicks,
      picture: data.picture,
      shopId: data.shopId,
      userId: data.userId,
      expirationDateUnixUtc: data.expirationDateUnixUtc,
      views: data.views
    };
    return story
  }


  private async toastError(error) {
    let message = '';
    switch (error.code) {
      case 'cancelled':
        message = this.ts.getLocalizedValue('ERRORS.OPERATION_WAS_CANCELLED');
        break;
      case 'unknown':
        message = this.ts.getLocalizedValue('ERRORS.UNKNOWN_ERROR_OCCURRED');
        break;
      case 'invalid-argument':
        message = this.ts.getLocalizedValue('ERRORS.ONE_OR_MORE_ARGS_INVALID');
        break;
      case 'deadline-exceeded':
        message = this.ts.getLocalizedValue('ERRORS.OPERATION_TIMED_OUT');
        break;
      case 'not-found':
        message = this.ts.getLocalizedValue('ERRORS.REQUESTED_DOCUMENT_OR_COLLECTION_NOT_FOUND');
        break;
      case 'already-exists':
        message = this.ts.getLocalizedValue('ERRORS.DOCUMENT_OR_COLLECTION_ALREADY_EXISTS');
        break;
      case 'permission-denied':
        message = this.ts.getLocalizedValue('ERRORS.YOU_DONT_HAVE_PERMS_TO_COMPLETE_ACTION');
        break;
      case 'resource-exhausted':
        message = this.ts.getLocalizedValue('ERRORS.RESOURCE_HAS_BEEN_EXHAUSTED');
        break;
      case 'failed-precondition':
        message = this.ts.getLocalizedValue('ERRORS.OPERATION_REJECTED_DUE_TO_SYSTEM_STATE');
        break;
      case 'aborted':
        message = this.ts.getLocalizedValue('ERRORS.OPERATION_WAS_ABORTED');
        break;
      case 'out-of-range':
        message = this.ts.getLocalizedValue('ERRORS.OPERATION_ATTEMPTED_PAST_VALID_RANGE');
        break;
      case 'unimplemented':
        message = this.ts.getLocalizedValue('ERRORS.REQUESTED_FEATURE_OR_METHOD_NOT_IMPLEMENTED');
        break;
      case 'internal':
        message = this.ts.getLocalizedValue('ERRORS.INTERNAL_ERROR_OCCURRED');
        break;
      case 'unauthenticated':
        message = this.ts.getLocalizedValue('ERRORS.NEED_AUTH_TO_PERFORM_ACTION');
        break;
      case 'data-loss':
        message = this.ts.getLocalizedValue('ERRORS.DATALOSS_OCCURRED_WHILE_TRANSMITTING_TO_FIREBASE');
        break;
      case 'unavailable':
        message = this.ts.getLocalizedValue('ERRORS.ARE_YOU_OFFLINE') + '?';
        break;
      default:
        message = error.message;
        break;
    }
    console.log(error);
    await this.toastsService.showToast(message, 'Eish...');
  }
}

