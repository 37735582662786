import { Injectable } from '@angular/core';
import { Shop, ShopWithDealz, Deal } from '../models/interfaces';
import { DatabaseService } from './database.service';
import { BehaviorSubject } from 'rxjs';
import { DateTimeService } from './date-time.service';
import { GisService } from './gis.service';

@Injectable({
  providedIn: 'root'
})
export class ShopDealzService {
  private _browsingShopsWithDealz:ShopWithDealz[]=[]
  browsingShops$: BehaviorSubject<ShopWithDealz[]> = new BehaviorSubject<ShopWithDealz[]>([]);
  constructor(
    private dateTimeService:DateTimeService,
    private database:DatabaseService,
    private gisService:GisService
  ) { 

  }

  async fetchaSpecial(shopOwnerId:string,restaurantId:string,specialId:string){
    const specialInMemory = this.returnSpecialFromBrowsingSpecialsIfThere(specialId)
    if(specialInMemory){
      return specialInMemory
    }else{
      return this.database.fetchaSpecial(shopOwnerId,restaurantId,specialId);
    }
  }
  
  returnSpecialFromBrowsingSpecialsIfThere(specialUid:string){
    for (let index = 0; index < this._browsingShopsWithDealz.length; index++) {
      const shop = this._browsingShopsWithDealz[index]
      const foundDeal = shop.dealz.find(deal=>{
        return deal.id == specialUid
      })
      if(foundDeal){
        return foundDeal  
      }
    }
  }
  
  async fetchShopsInArea(center:[number,number],radiusInM:number){
   let shops = await this.database.fetchAllShopsAtLocation(center,radiusInM);
    this.gisService.sortTheseObjectsByDistanceFrom(
      shops,
      center[1],
      center[0]
    )
    const browsingShopsWithDealz = await this.getActiveDealzOfTheseShops(shops)
    this.browsingShops$.next(browsingShopsWithDealz);
  }  

  async fetchDealzOfThisShop(shopUserId){
      return this.database.getShopActiveDealz({
        id:shopUserId,
        name:null
      })
   }  
  
   async fetchShopsOfThisUser(shopUserId){
    return this.database.getShopWithShopUserId(shopUserId);
 }  

  giveShopDayFromTradingHours(restaurant:Shop,day:'Monday'|'Tuesday'|'Wednesday'|'Thursday'|'Friday'|'Saturday'|'Sunday'){
    if(!day||!restaurant){
      throw new Error("restaurant or day is nullish"); 
    }
    switch (day) {
      case 'Monday':
      case 'Tuesday':
      case 'Wednesday':
      case 'Thursday':
      case 'Friday':
        return restaurant.trading_from
      case 'Saturday':
        return restaurant.saturday_trading_from
      case 'Sunday':
        return restaurant.sunday_trading_from
      default:
        return restaurant.holiday_trading_from
    }
  }
  
  giveShopDayToTradingHours(restaurant:Shop,day:'Monday'|'Tuesday'|'Wednesday'|'Thursday'|'Friday'|'Saturday'|'Sunday'){
    if(!day||!restaurant){
      throw new Error("restaurant or day is nullish"); 
    }
    switch (day) {
      case 'Monday':
      case 'Tuesday':
      case 'Wednesday':
      case 'Thursday':
      case 'Friday':
        return restaurant.trading_to
      case 'Saturday':
        return restaurant.saturday_trading_to
      case 'Sunday':
        return restaurant.sunday_trading_to
      default:
        return restaurant.holiday_trading_to
    }
  }

  isShopAvailableNowOrLaterOrMissed(restaurant:Shop,day:'Monday'|'Tuesday'|'Wednesday'|'Thursday'|'Friday'|'Saturday'|'Sunday'){
    const from = this.giveShopDayFromTradingHours(restaurant,day)
    const to = this.giveShopDayToTradingHours(restaurant,day)
    
    if(this.dateTimeService.isCurrentTimeBetween(from,to)){
      return 'now'
    }
    if(this.dateTimeService.isCurrentTimeBefore(from)){
      return 'later'
    }
    if(this.dateTimeService.isCurrentTimeAfter(to)){
      return 'missed'
    }
  }

  private async getActiveDealzOfTheseShops(shops:Shop[]){
    let promises:Promise<Deal[]>[]= [];
    shops.forEach(shop => {
      promises.push(this.database.getShopActiveDealz(shop))
    });
    let dealzPerShop = await Promise.all(promises);
    return this.constructBrowsingShopsWithDealzFromArrayOfShops(shops,dealzPerShop);
  }

  private constructBrowsingShopsWithDealzFromArrayOfShops(shops:Shop[],dealzPerShop:Deal[][]){
    this._browsingShopsWithDealz.splice(0,this._browsingShopsWithDealz.length)
    shops.forEach((shop)=>{
      let shopDealzArray = dealzPerShop.find((dealz)=>{
        if(dealz.length > 0){
          return dealz[0].restaurant_id == shop.id
        }
        return false
      })
      this._browsingShopsWithDealz.push({...shop,dealz:shopDealzArray?shopDealzArray:[]})
     
    })
    return this._browsingShopsWithDealz
  }
}

