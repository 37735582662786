import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class RegexpatternsService {

  constructor() { }

  /*
  * Use for form validation
  * European characters included and space and -
  * Try it https://www.debuggex.com
  */
  static getNameValidatorPattern(): RegExp {
      return /^[a-zA-Z\u00c0-\u017e]+[a-zA-Z\u00c0-\u017e\- ]*\s*$/;
  }

  static getTimeValidatorPattern():RegExp{
    return /^[0-2]{1}[0-9]{1}:[0-5]{1}[0-9]{1}/
}

  /**
  * Use for form validation
  * European characters included and numbers and space and - and newline
  * Try it https://www.debuggex.com
  */
  static getAddressValidatorPattern(): RegExp {
      return /^[a-zA-Z0-9\u00c0-\u017e]+[a-zA-Z0-9.\u00c0-\u017e\- \r\n]*\s*$/;
  }

  /**
  * Use for form validation
  * Alphanumeric; letters and numbers and space and -
  * Try it https://www.debuggex.com
  */
  static getAlphanumericValidatorPattern(): RegExp {
      return /^[a-zA-Z0-9`']+[a-zA-Z0-9\-'` ]*\s*$/;
  }

  /**
  * Use for form validation
  * Try it https://www.debuggex.com
  */
  static getEmailValidatorPattern(): RegExp {
      return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})+\s*$/;
  }

  /**
  * Use for form validation
  * Try it https://www.debuggex.com
  */
  static getPhoneValidatorPattern(): RegExp {
      return /^\+\d{1,3}[\- ]?\d+[\- ]?\d+[\- ]?\d+[\- ]?\d+\s*$/;
  }

  /**
  * Use for form validation
  * Try it https://www.debuggex.com
  */
  static getPasswordValidatorPattern(): RegExp {
      return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/;
  }

  static getBaseCurrency(): string {
      return 'ZAR';
  }

  /**
  * Based on this answer on https://stackoverflow.com/a/2117523/2081047
  */
  getUuidV4() {
      // @ts-ignore
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          // DO allow the bitwise operations below
          // tslint:disable-next-line:no-bitwise
          (
              c ^
              (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
      );
  }

  /**
  * trimInputForm trims (removes whitespace) from the fields of type string.
  * Maybe change this to use directive or pipe or validator if needed?
  */
  trimInputForm(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(key => {
          const value = formGroup.get(key).value;
          if (typeof value === 'string') {
              formGroup.get(key).setValue(value.trim());
          }
      });
  }
}
