import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import {
  FirebaseMessaging,
  GetTokenOptions,
  NotificationActionPerformedEvent,
  NotificationReceivedEvent,
  PermissionStatus,
  TokenReceivedEvent,
} from "@capacitor-firebase/messaging";
import { DatabaseService } from './database.service';
import { environment } from 'src/environments/environment';
import { YumdealzUserService } from './yumdealz-user.service';
import { NativeSettingsService } from './native-settings.service';
import { LoadingService } from './loading.service';
import { TranslationService } from './translation.service';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  private readonly defaultKeys = ['type', 'google.ttl', 'google.sent_time', 'google.original_priority', 'google.delivered_priority', 'from',
    'collapse_key']
  private webEventCallback = (event: any) => {
    console.log("serviceWorker message: ", { event });
    const notification = new Notification(event.data.notification.title, {
      body: event.data.notification.body,
    });
    notification.onclick = (event) => {
      console.log("notification clicked: ", { event });
    };
  }
  private _pushToken: string = null;
  get pushToken() {
    return this._pushToken;
  }
  constructor(
    private dbService: DatabaseService,
    private alertCtrl: AlertController,
    private nativeSettingsService: NativeSettingsService,
    private loadingService: LoadingService,
    private ts: TranslationService
  ) {
    this.setupService()
  }

  async saveTokenIfPossible() {
    if (this.pushToken) {
      let notificationsEnabled = await this.checkPermissions()
      await this.dbService.refreshOrAddPushToken(this._pushToken, notificationsEnabled);
    }
  }

  async checkPermissions() {
    let state = await FirebaseMessaging.checkPermissions();
    if (!state) {
      return false
    }
    return this.permissionsPassed(state);
  }

  async askForPermissions(forceAsk = true) {
    const mainAsk = this.ts.getLocalizedValue('PUSH_NOTIFICATION_SERVICE.NEVER_MISS_GREAT_DEAL_AGAIN')
    let state = await FirebaseMessaging.checkPermissions();
    if (!state) {
      return false
    }

    if (this.permissionsPassed(state)) {
      return true
    }

    if (state.receive == 'denied') {
      if (!forceAsk) {
        return false
      }

      let explainAlert = await this.alertCtrl.create({
        message: mainAsk + this.ts.getLocalizedValue('PUSH_NOTIFICATION_SERVICE.YOU_HAVE_PREVIOUSLY_DECLINED_PUSH_NOTIFICATIONS'),
        cssClass: `custom-alert-shape`,
        mode: 'ios',
        buttons: [
          {
            text: this.ts.getLocalizedValue('PUSH_NOTIFICATION_SERVICE.OPEN_SETTINGS'),
            handler: async () => {
              await this.nativeSettingsService.openNativeAppSettings();
            }
          }
        ]
      });

      await explainAlert.present();
      return explainAlert.onDidDismiss().then(() => {
        return false
      })

    } else {
      let wasGranted = false
      let explainAlert = await this.alertCtrl.create({
        message: `${mainAsk}`,
        cssClass: `custom-alert-shape`,
        mode: 'ios',
        buttons: [
          {
            text: this.ts.getLocalizedValue('PUSH_NOTIFICATION_SERVICE.NOT_NOW'),
            handler: () => {
              wasGranted = false
            }
          },
          {
            text: this.ts.getLocalizedValue('PUSH_NOTIFICATION_SERVICE.YUM') + '!',
            handler: async () => {
              await this.loadingService.showOrContinueShowingLoading();
              try {
                wasGranted = await this.requestPermissionsAndRegister();
              } catch (error) {
                wasGranted = false
              }
              await this.loadingService.hideLoading();
            }
          },
        ]
      });
      await explainAlert.present();
      return explainAlert.onDidDismiss().then(() => {
        return wasGranted
      });
    }
  }

  async getDeliveredNotifications() {
    const notificationList = await FirebaseMessaging.getDeliveredNotifications();
    console.log('delivered notifications', notificationList.notifications);
  }

  async deleteToken() {
    try {
      await FirebaseMessaging.deleteToken();
    } catch (error) {
      console.log(error);
    }
    await this.dbService.rmPushToken(this._pushToken);
    this._pushToken = null;
  }

  async subscribeToShopTopic(shopUserId: string) {
    if (Capacitor.isNativePlatform()) {
      await FirebaseMessaging.subscribeToTopic({
        topic: shopUserId
      });
    }
  }

  async unsubscribeFromShopTopic(shopUserId: string) {
    if (Capacitor.isNativePlatform()) {
      await FirebaseMessaging.unsubscribeFromTopic({
        topic: shopUserId
      });
    }
  }

  private async registerPushNotifications() {
    const options: GetTokenOptions = {
      vapidKey: environment.firebaseConfig.vapidKey,
    };
    if (Capacitor.getPlatform() === "web") {
      options.serviceWorkerRegistration =
        await navigator.serviceWorker.register("firebase-messaging-sw.js");
    }
    let { token } = await FirebaseMessaging.getToken(options);
    this._pushToken = token;
    await this.saveTokenIfPossible()
  }

  private listenForTokenReceived() {
    // this potentially fires before a user is set in the backend (when the app opens it seems
    // the native sdk already sends 2 tokens for some reason)
    FirebaseMessaging.addListener('tokenReceived', async (e: TokenReceivedEvent) => {
      this._pushToken = e.token;
      console.log(this._pushToken);
    });
  }

  private async requestPermissionsAndRegister() {
    let wasGranted = await this.requestPermission();
    if (wasGranted) {
      await this.registerPushNotifications();
    }
    return wasGranted
  }

  private listenForNotificationActedUpon() {
    FirebaseMessaging.addListener('notificationActionPerformed', async (notification: NotificationActionPerformedEvent) => {
      this.pushNotificationActionResponse_voucherPush_tap(notification)
    })
  }

  private async stopListening() {
    await FirebaseMessaging.removeAllListeners()
  }

  private async pushNotificationActionResponse_voucherPush_tap(notification: NotificationActionPerformedEvent) {

    if (notification.actionId == 'tap' && notification.notification.data['type'] == "voucherPush") {

      console.log('voucherPush notification was tapped');

      // let strippedFromDefaultKeysArray = Object.keys(notification.notification.data).filter(
      //   (key)=>{return !this.defaultKeys.includes(key)}
      // );

      // let strippedFromDefaultKeysObj={};

      // strippedFromDefaultKeysArray.forEach(key=>{
      //   strippedFromDefaultKeysObj[key]=notification.notification.data[key]
      // });

      // this.receivedNotificationObj=strippedFromDefaultKeysObj;

      // const modal = await this.modalCtrl.create({
      //   component: VoucherClaimPagePage,
      //   backdropDismiss:false,
      //   componentProps:{
      //     receivedNotificationObj:this.receivedNotificationObj,
      //     userId
      //   }
      // });

      // await modal.present();
      // await modal.onDidDismiss().then(() => {

      // })
    }

  }

  private listenForNotificationReceived() {
    FirebaseMessaging.addListener('notificationReceived', async (notification: NotificationReceivedEvent) => {
      console.log('pushNotificationReceived', notification);
    })

  }

  private permissionsPassed(status: PermissionStatus) {
    switch (status.receive) {
      case 'denied':
      case 'prompt':
      case 'prompt-with-rationale':
        return false
      case 'granted':
        return true
      default:
        return false;
    }
  }

  private async requestPermission() {
    let permissionStatus = await FirebaseMessaging.requestPermissions();
    return this.permissionsPassed(permissionStatus)
  }

  private async setupService() {
    await this.stopListening();
    this.listenForTokenReceived();
    await this.getDeliveredNotifications();
    this.listenForNotificationActedUpon();
    this.listenForNotificationReceived();
    if (Capacitor.getPlatform() === "web") {
      navigator.serviceWorker.removeEventListener("message", this.webEventCallback)
      navigator.serviceWorker.addEventListener("message", this.webEventCallback);
    };
    let hasPermissions = await this.checkPermissions();
    if (hasPermissions) {
      await this.tryToGetFCMPushToken();
    }
  }

  private async tryToGetFCMPushToken() {
    try {
      await this.registerPushNotifications();
    } catch (error) {
      console.log(error);
    }
  }
}
