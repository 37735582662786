import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-stamp',
  templateUrl: './loading-stamp.component.html',
  styleUrls: ['./loading-stamp.component.scss'],
})
export class LoadingStampComponent  implements OnInit {
  constructor() { }

  ngOnInit() {
  
  }

}
