import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { ConfettiService } from './confetti.service';
import { ToastServiceService } from './toast-service.service';
import { Deal, Shop, Voucher, WalletLoyaltyCard } from '../models/interfaces';
import { BarcodeScannerService } from './barcode-scanner.service';
import { NfcService } from './nfc-read.service';
import { HapticsService } from './haptics.service';
import { Capacitor } from '@capacitor/core';
import { NetworkStatusService } from './network-status.service';
import { LoyaltyCardService } from './loyalty-card.service';
import { VouchersService } from './vouchers.service';
import { ShopDealzService } from './shop-dealz.service';
import { YumdealzUserService } from './yumdealz-user.service';
import { AuthService } from './auth.service';
import { ModalController } from '@ionic/angular';
import { LoyaltyCardPickerComponent } from '../shared/components/loyalty-card-picker/loyalty-card-picker.component';
import { DealPickerComponent } from '../shared/components/deal-picker/deal-picker.component';
import { LoyaltyCardPreviewComponent } from '../shared/components/loyalty-card-preview/loyalty-card-preview.component';
import { DateTimeService } from './date-time.service';
import { LoadingService } from './loading.service';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Nfc } from '@capawesome-team/capacitor-nfc';
import { LocationService } from './location.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class ScanService {
  private _isNfcSupported: boolean = false;
  get isNfcSupported() {
    return this._isNfcSupported
  }
  /**
   * Will emit a number when a voucher is received.
   * The number is the amount of seconds to celebrate for (how long the fireworks animation will play).
   * Can be used in other pages to celebrate for that amount of time
   */
  private _voucherCelebrationBroadcast = new Subject<number>();
  get voucherCelebrationBroadcast() {
    return this._voucherCelebrationBroadcast
  }

  private _isScanning$ = new BehaviorSubject<boolean>(false)
  get isScanning$() {
    return this._isScanning$
  }


  constructor(
    private alertService: AlertService,
    private confettiService: ConfettiService,
    private toasts: ToastServiceService,
    private barcodeScannerService: BarcodeScannerService,
    private nfcReader: NfcService,
    private hapticsService: HapticsService,
    private network: NetworkStatusService,
    private loyaltyCardService: LoyaltyCardService,
    private vouchersService: VouchersService,
    private shopDealzService: ShopDealzService,
    private modalCtrl: ModalController,
    private authService: AuthService,
    private dateTimeService: DateTimeService,
    private loadingService: LoadingService,
    private locationService: LocationService,
    private ts: TranslationService,
  ) {
    this.nfcReader.isSupported().then((res) => {
      //  if(Capacitor.getPlatform()=='ios'){
      this._isNfcSupported = res
      // }else{
      //   // temporary until android nfc bugs are fixed
      //   this._isNfcSupported = false
      // }
    })

    this.nfcReader.isScanning$.subscribe((isScanning) => {
      this.isScanning$.next(isScanning);
    })

    this.barcodeScannerService.isScanning$.subscribe((isScanning) => {
      this.isScanning$.next(isScanning);
    })
  }

  async scanQrOrTagAndReturnValue() {

    const tryScanTagNfc = async () => {
      try {
        let readRes = await this.nfcReader.readTagForText();
        if (!readRes) {
          return false
        }
        return readRes.result
      } catch (error) {
        console.log(error);
      }
    }

    const tryScanTagQr = async () => {
      try {
        let scanRes = await this.barcodeScannerService.scan();

        if (!scanRes.result.hasContent) {
          await this.toasts.showToast(
            this.ts.getLocalizedValue('SCAN_SERVICE.NOTHING_SCANNED'),
            this.ts.getLocalizedValue('SCAN_SERVICE.TRY_AGAIN')
          );
          return false
        }

        return scanRes.result.content
      } catch (error) {
        console.log(error);
      }
    }

    let shopUserId;
      if(Capacitor.isNativePlatform()){ // for testing - but sheesh, scary...
        if(this.isNfcSupported) {
          shopUserId = await tryScanTagNfc();
        } else {
          shopUserId = await tryScanTagQr();
        }
        if(!shopUserId){
          return;
        }
      }else{
        // just the demo account to test with.
        this.isScanning$.next(true);
        shopUserId = await new Promise<string>((resolve) => {
          setTimeout(()=>{
            this.isScanning$.next(false);
            resolve("04AkCqA6BDZU5YjXXah8KoTacFM2")
          },3000)
        }) 
      }

    return shopUserId
  }

  /**
  * Scans a tag or qr code and checks if user
  * has the card in his/her wallet before giving a stamp.
  * If not it will first join the user to the programme and 
  * then give a stamp.
  */
  async smartScan(shopUserId: string) {
    const manyCardsInWalletScan = async (userCardsAtShop: WalletLoyaltyCard[], shop: Shop) => {
      const dealPickerModal = await this.modalCtrl.create({
        component: LoyaltyCardPickerComponent,
        mode: 'ios',
        initialBreakpoint: 1,
        breakpoints: [0, 1],
        handle: false,
        componentProps: {
          loyaltyCards: userCardsAtShop
        }
      })
      await dealPickerModal.present()
      await this.loadingService.hideLoading()
      return dealPickerModal.onDidDismiss().then(async (detail) => {
        let chosenCard = detail.data.card as WalletLoyaltyCard
        if (chosenCard) {
          const deal = await this.shopDealzService.fetchaSpecial(chosenCard.userId, chosenCard.restaurantId, chosenCard.special_id);
          await giveStamp(chosenCard, deal, shop);
        }
      })
    }

    const noCardsInWalletScan = async (shop: Shop) => {
      // check if the shop has active dealz
      const dealz = await this.shopDealzService.fetchDealzOfThisShop(shop.id); // TODO this will return just one shop - what if there are many??

      // no dealz to join
      if (dealz.length == 0) {
        await this.loadingService.hideLoading()
        await this.toasts.showErrorToast(this.ts.getLocalizedValue('SCAN_SERVICE.NO_DEALZ_TO_JOIN'))
      }

      // join and get stamp
      if (dealz.length == 1) {
        await joinProgrammeAndGiveStamp(dealz[0], shop)
      }

      // user should choose what dealz to join if there are many
      if (dealz.length > 1) {
        const dealPickerModal = await this.modalCtrl.create({
          component: DealPickerComponent,
          mode: 'ios',
          initialBreakpoint: 1,
          breakpoints: [0, 1],
          handle: false,
          componentProps: {
            shopWithDealz: { ...shop, dealz }
          }
        })
        await dealPickerModal.present();
        await this.loadingService.hideLoading();
        return dealPickerModal.onDidDismiss().then(async (detail) => {
          let chosenDeal: Deal
          if (detail.data) {
            chosenDeal = detail.data.deal
          }
          if (chosenDeal) {
            await this.loadingService.showOrContinueShowingLoading();
            await joinProgrammeAndGiveStamp(chosenDeal, shop);
          }
        })
      }
    }

    const oneCardInWalletScan = async (card: WalletLoyaltyCard, shop: Shop) => {
      const deal = await this.shopDealzService.fetchaSpecial(card.userId, card.restaurantId, card.special_id);
      await giveStamp(card, deal, shop)
    }

    const joinProgrammeAndGiveStamp = async (deal: Deal, shop: Shop) => {
      await this.loyaltyCardService.joinLoyalty(deal, this.authService.giveFirebaseAuth().currentUser);
      const cards = this.loyaltyCardService.giveUserLoyaltyCardsAtShop(shop.user_uid); //TODO potential race condition (unless it resolves after wallet content subscriptions are notified)
      await giveStamp(cards.find(card => { return card.special_id = deal.id }), deal, shop)
    }

    const giveStamp = async (card: WalletLoyaltyCard, deal: Deal, shop: Shop) => {
      const gotStampButNotVoucher = await this.letUserGetStamp(shop.id, card, deal, false);
      await this.loadingService.hideLoading();
      if (gotStampButNotVoucher) {
        await this.previewCard(deal, shop);
      }
    }

    try {
      const userCardsAtShop = this.loyaltyCardService.giveUserLoyaltyCardsAtShop(shopUserId);
      const shop = await this.shopDealzService.fetchShopsOfThisUser(shopUserId);
      // if user has no cards we should allow the user to add a card
      if (userCardsAtShop.length == 0) {
        await noCardsInWalletScan(shop)
      }

      // give stamp for the only card in wallet
      if (userCardsAtShop.length == 1) {
        await oneCardInWalletScan(userCardsAtShop[0], shop)
      }

      if (userCardsAtShop.length > 1) {
        await manyCardsInWalletScan(userCardsAtShop, shop)
      }
      await this.loadingService.hideLoading();
    } catch (error) {
      await this.loadingService.hideLoading();
      await this.toasts.showToast(`Could not get stamp ${!this.network.isOnline() ? '(You are offline)' : ''}`, 'Eish...')
      console.log(error);
    }
  }

  async stopScanning() {
    try {
      await this.nfcReader.stopScanning();
    } catch (error) {
      console.log(error);
    }
  }

  async scanLoyaltyCard(loyaltyCard: WalletLoyaltyCard, deal: Deal) {
    try {
      await this.hapticsService.hapticsImpactLight();
      let scanContent;
      if (this.isNfcSupported) {
        scanContent = await this.tryScanLoyaltyCardNfc(loyaltyCard);
      } else {
        scanContent = await this.tryScanLoyaltyCardQr(loyaltyCard);
      }
      if (!scanContent) {
        return;
      }
      return scanContent

    } catch (error) {
      await this.toasts.showToast(`Could not get stamp ${!this.network.isOnline() ? '(You are offline)' : ''}`, 'Eish...')
      console.log(error);
    }
  }

  async scanVoucher(voucher: Voucher) {
    try {
      await this.hapticsService.hapticsImpactLight()
      let scanContent
      if (this.isNfcSupported) {
        scanContent = await this.tryScanVoucherNfc(voucher);
      } else {
        scanContent = await this.tryScanVoucherQr(voucher);
      }
      if (!scanContent) {
        return
      }
      return scanContent;
    } catch (err) {
      console.log(err);
      await this.toasts.showToast(
        this.ts.getLocalizedValue('SCAN_SERVICE.TRY_AGAIN_LATER'),
        this.ts.getLocalizedValue('SCAN_SERVICE.FAILED_TO_REDEEM_VOUCHER')
      );
    }
  }

  async checkIfCameraPermissionsGranted() {
    const status = await BarcodeScanner.checkPermission({ force: false });
    if (status.granted) {
      return true;
    }
    return false;
  }

  async checkIfCameraPermissionsDenied() {
    const status = await BarcodeScanner.checkPermission({ force: false });
    if (status.denied) {
      return true;
    }
    return false
  }

  async askCameraPermissions() {
    await this.barcodeScannerService.tryToAskPermission()
  }

  async checkIfNfcIsOn() {
    const supported = await Nfc.isSupported();
    if (Capacitor.getPlatform() == 'ios') {
      return supported.isSupported
    } else {
      return (await Nfc.isEnabled()).isEnabled
    }
  }

  private async previewCard(deal: Deal, shop: Shop) {
    await this.hapticsService.hapticsImpactLight()
    const card = this.loyaltyCardService.giveCardInWalletForThisSpecial(deal)
    const modal = await this.modalCtrl.create({
      component: LoyaltyCardPreviewComponent,
      mode: 'ios',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      handle: false,
      componentProps: {
        loyaltyCard: card,
        deal,
        shop,
        mode: 'previewWithScan',
        today: this.dateTimeService.giveLocalNowDay()
      }
    });
    await modal.present();
  }



  private async tryScanLoyaltyCardNfc(loyaltyCard: WalletLoyaltyCard) {
    try {
      let readRes = await this.nfcReader.readTagForText()

      if (!readRes) {
        return false
      }

      if (readRes.result != loyaltyCard.userId) {
        await this.toasts.showToast(
          this.ts.getLocalizedValue('SCAN_SERVICE.DEAL_DOESNT_BELONG_TO_SHOP_YOU_ARE_IN'),
          'Eish...'
        );
        return false
      }

      return readRes.id
    } catch (error) {
      console.log(error);
    }
  }

  private async tryScanLoyaltyCardQr(loyaltyCard: WalletLoyaltyCard) {
    try {
      let scanRes = await this.barcodeScannerService.scan();
      if (!scanRes.result.hasContent) {
        await this.toasts.showToast(
          this.ts.getLocalizedValue('SCAN_SERVICE.NOTHING_WAS_SCANNED'),
          this.ts.getLocalizedValue('SCAN_SERVICE.TRY_AGAIN'));
        return false
      }

      if (scanRes.result.content != loyaltyCard.userId) {
        await this.toasts.showToast(
          this.ts.getLocalizedValue('SCAN_SERVICE.DEAL_DOESNT_BELONG_TO_SHOP_YOU_ARE_IN'),
          'Eish...');
        return false
      }

      return scanRes.result.content
    } catch (error) {
      console.log(error);
    }
  }

  private async showGetStampAlert(loyaltyCard: WalletLoyaltyCard, deal: Deal) {
    try {
      const stampElement = document.getElementById('nextStampSlot_0')
      let stamp = null;
      let busrtPos = null;
      if (stampElement) {
        stamp = document.getElementById('nextStampSlot_0').getBoundingClientRect();
        console.log(stamp);
        let busrtPos = {
          x: (stamp.x / window.innerWidth) * 1,
          y: (stamp.y / window.innerHeight) * 1
        }
        console.log(busrtPos);
      }

      this.confettiService.confettiCannonBurst(4, 1, busrtPos);
      //this.confettiService.fireWorksWithAudio()
    } catch (error) {
      console.log(':( no confetti', error);
    }
  }

  async letUserGetStamp(scanContent: string, loyaltyCard: WalletLoyaltyCard, deal: Deal, checkTag = true) {
    const stampReceivedReaction = async (newStampCount) => {
      if (newStampCount !== null && newStampCount !== undefined) {
        await this.showGetStampAlert(loyaltyCard, deal);
        return true

      } else {
        //await this.showReceivedVoucherAlert();
        this.celebrateVoucherReceived();
        return false
      }

    }

    const stampNotReceivedReaction = async (err) => {
      await this.toasts.showErrorToast(this.ts.getLocalizedValue('SCAN_SERVICE.COULD_NOT_GET_STAMP'));
      console.log(err);
      return false
    }

    try {
      if (this.isNfcSupported && checkTag) {
        return this.loyaltyCardService.receiveStampNFC(loyaltyCard, deal, scanContent, {
          lat: this.locationService.userLocation.lat,
          lng: this.locationService.userLocation.lng
        }).then(async (newStampCount) => {
          return await stampReceivedReaction(newStampCount)
        }).catch(async (err) => {
          return await stampNotReceivedReaction(err)
        })
      } else {
        return this.loyaltyCardService.receiveStamp(loyaltyCard, deal, {
          lat: this.locationService.userLocation.lat,
          lng: this.locationService.userLocation.lng
        }).then(async (newStampCount) => {
          return await stampReceivedReaction(newStampCount)
        }).catch(async (err) => {
          return await stampNotReceivedReaction(err)
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  async letUserRedeemVoucher(scanContent: string, voucher: Voucher) {
    try {
      if (this.isNfcSupported) {
        await this.vouchersService.redeemVoucherNFC(voucher, scanContent).then(async () => {
          await this.showRedeemedVoucherAlert()
        }).catch(async (err) => {
          await this.toasts.showErrorToast(this.ts.getLocalizedValue('SCAN_SERVICE.COULD_NOT_REDEEM_VOUCHER'));
          console.log(err);
        })
      } else {
        await this.vouchersService.redeemVoucher(voucher).then(async () => {
          await this.showRedeemedVoucherAlert()
        }).catch(async (err) => {
          await this.toasts.showErrorToast(this.ts.getLocalizedValue('SCAN_SERVICE.COULD_NOT_REDEEM_VOUCHER'));
          console.log(err);
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  private async tryScanVoucherNfc(voucher: Voucher) {
    try {
      let readRes = await this.nfcReader.readTagForText();

      if (!readRes) {
        return false
      }

      if (readRes.result != voucher.userId) {
        await this.toasts.showToast(
          this.ts.getLocalizedValue('SCAN_SERVICE.DEAL_DOESNT_BELONG_TO_SHOP_YOU_ARE_IN'),
          'Eish...');
        return false
      }

      return readRes.id
    } catch (error) {
      console.log(error);
    }
  }

  private async tryScanVoucherQr(voucher: Voucher) {
    try {
      let scanRes = await this.barcodeScannerService.scan();
      if (!scanRes.result.hasContent) {
        await this.toasts.showToast(
          this.ts.getLocalizedValue('SCAN_SERVICE.NOTHING_WAS_SCANNED'),
          this.ts.getLocalizedValue('SCAN_SERVICE.TRY_AGAIN'));
        return false
      }

      if (scanRes.result.content != voucher.userId) {
        await this.toasts.showToast(
          this.ts.getLocalizedValue('SCAN_SERVICE.DEAL_DOESNT_BELONG_TO_SHOP_YOU_ARE_IN'),
          'Eish...');
        return false
      }

      return scanRes.result.content
    } catch (error) {
      console.log(error);
    }
  }

  private async showRedeemedVoucherAlert() {
    let alert = await this.alertService.redeemVoucherAlert();
    try {
      let interval = this.confettiService.confettiFireWorks(15);
      alert.onDidDismiss().then(() => {
        clearInterval(interval)
      })
    } catch (error) {
      console.log(':( no confetti', error);
    }
  }

  private async showReceivedVoucherAlert() {
    let alert = await this.alertService.receivedVoucherAlert();
    try {
      let interval = this.confettiService.confettiFireWorks(15);
      alert.onDidDismiss().then(() => {
        clearInterval(interval)
      })
    } catch (error) {
      console.log(':( no confetti', error);
    }
  }

  private celebrateVoucherReceived() {
    this.confettiService.confettiFireWorks(15);
    this.voucherCelebrationBroadcast.next(15);
  }
}
