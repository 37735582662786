import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Deal, ShopWithDealz } from 'src/app/models/interfaces';
import { AssetlinksService } from 'src/app/services/assetlinks.service';

@Component({
  selector: 'app-deal-picker',
  templateUrl: './deal-picker.component.html',
  styleUrls: ['./deal-picker.component.scss'],
})
export class DealPickerComponent  implements OnInit {
  @Input() shopWithDealz:ShopWithDealz;
  readonly cardHeight = '70vh'
  constructor(
    public assetLinksService:AssetlinksService,
    private modalController:ModalController
  ) { }

  ngOnInit() {}


  return(data = null) {
    this.modalController.dismiss(data);
  }

  selectDeal(deal:Deal){
    console.log('about to return this deal:',deal);
    
    this.return({deal})
  }
}
