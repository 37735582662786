import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-scanning-nfc-stamp',
  templateUrl: './scanning-nfc-stamp.component.html',
  styleUrls: ['./scanning-nfc-stamp.component.scss'],
})
export class ScanningNfcStampComponent  implements OnInit {
  @Input() mode:'button'
  constructor() { }

  ngOnInit() {}

}
