<div mode="ios" class="card" (click)="cardClick()" *ngIf="!loading"> 
  <div class="card-content">
    <div  id="title-container">
      <h2>{{special.descriptive_tag}}</h2>
    </div>
    <div id="img-container" class="fade-in">
      <img  
      id="background-image"
      [defaultImage]="assetLinksService.spinnerGif" 
      [lazyLoad]="special.picture" 
      [errorImage]="assetLinksService.favicon"
      onerror="this.src='assets/favicon.png'">
      <!-- <ion-spinner  name="dots"></ion-spinner> -->
    </div>
    <ion-item style="text-align: center;">
      <ion-label color="secondary">
        <strong>{{special.restaurant_name}} | {{special.name}}</strong>
        <p>{{loyaltyCard.expiryDate | daysLeft}}</p>
      </ion-label>
    </ion-item>
      <div id="stamps-container" (click)="stampsClick($event)">
        <div class="stamp-row" >
          <div class="stamp-container" *ngFor="let in of arrayMaker(loyaltyCard.stickers_count)" size="4">
            <img  class="stamp" [src]='assetLinksService.stamp'> 
          </div>
          <div class="stamp-container"  [id]="'nextStampSlot_'+in" *ngFor="let in of arrayMaker(special.stamps_required - loyaltyCard.stickers_count)" size="4">
            <img  class="stamp" [src]='assetLinksService.stampHolder'>
          </div>
        </div>
  
        <ion-fab *ngIf="mode=='cardScan'"  edge="true" vertical="top" horizontal="end" shape="round" slot="fixed">
          <ion-fab-button [disabled]="dateTimeService.isExpired(loyaltyCard.expiryDate) || isButtonLoading"   (click)="actionButton()">
            <ion-icon *ngIf="!scanService.isNfcSupported && !isButtonLoading" name="qr-code-outline"></ion-icon>
            <ion-icon *ngIf="scanService.isNfcSupported && !isButtonLoading" size="large" src="assets/contactless-icon.svg"></ion-icon>
            <ion-spinner *ngIf="isButtonLoading" name="dots"></ion-spinner>
          </ion-fab-button>
        </ion-fab>
      </div>
  </div>
</div>


<div
style="height: 70vh; margin: 0.5em;"
mode="ios"
*ngIf="loading">
<ion-skeleton-text animated style="width: 100%;height: 100%;"></ion-skeleton-text>
</div>

