import { Injectable } from '@angular/core';
import { LoadingController, LoadingOptions } from '@ionic/angular';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private theLoader: HTMLIonLoadingElement;
  private readonly loaderId = 'YumDealzLoader'
  private defaultOptions: LoadingOptions
  constructor(
    private loadingCtrl: LoadingController,
    private ts: TranslationService
  ) {
    this.defaultOptions = {
      id: this.loaderId,
      cssClass: 'spinner-rounded-background',
      translucent: true,
      showBackdrop: true,
      spinner: 'bubbles',
    }
    this.createLoader();
  }

  /**
   * You can call this multiple times
   * and it will only show one overlay
   */
  async showOrContinueShowingLoading() {
    if (!this.theLoader) {
      await this.createLoader()
    }
    await this.theLoader.present();
    return this.theLoader
  }

  async hideLoading() {
    await this.theLoader.dismiss();
    await this.createLoader();
  }

  updateLoaderMessage(msg: string) {
    this.theLoader.message = msg
  }

  private async createLoader() {
    this.theLoader = await this.loadingCtrl.create(this.defaultOptions)
    this.theLoader.message = this.ts.getLocalizedValue('INFO.PLEASE_WAIT')
  }
}
