import { Component, Input, OnInit } from '@angular/core';
import { Shop } from 'src/app/models/interfaces';
import { StoryViewerComponent } from '../story-viewer/story-viewer.component';
import { ModalController } from '@ionic/angular';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { RestaurantPage } from 'src/app/pages/restaurant/restaurant.page';
import { DateTimeService } from 'src/app/services/date-time.service';

@Component({
  selector: 'app-shop-avatar',
  templateUrl: './shop-avatar.component.html',
  styleUrls: ['./shop-avatar.component.scss'],
})
export class ShopAvatarComponent  implements OnInit {
  @Input() shop:Shop
  constructor(
    private modalCtrl:ModalController,
    public assetLinksService:AssetlinksService,
    private dateTimeService:DateTimeService
  ) { }

  ngOnInit() {}

  async viewStory(){
    const modal = await this.modalCtrl.create({
      component: StoryViewerComponent,
      initialBreakpoint: 1,
      breakpoints: [0,1],
      handle:false,
      mode:'ios',
      componentProps:{
        shop:this.shop
      }
    });
    await modal.present();
    modal.onDidDismiss().then(res=>{
      // display as now viewed
     // storyAvatar.viewed = true
    })
  }

   async viewShop(){
    try {
      const modal = await this.modalCtrl.create({
        component: RestaurantPage,
       // presentingElement: this.routerOutlet.nativeEl,
        componentProps: {
          shop:this.shop,
          today:this.dateTimeService.giveLocalNowDay()
        }
      });
      await modal.present();
    } catch (error) {
      console.error(error)
    }
  }
}
