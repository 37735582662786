import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesService } from './preferences.service';

export enum SupportedLanguagesEnum{
  AFRIKAANS = 'af',
  ENGLISH = 'en',
  RUSSIAN = 'ru'
}

type SupportedLanguagesTranslationKeys = {
  [key in SupportedLanguagesEnum]:{
    TRANSLATION_KEY:string,
  }
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private _supportedLanguages: SupportedLanguagesTranslationKeys = {
    [SupportedLanguagesEnum.ENGLISH]:{
      TRANSLATION_KEY:'LANGUAGE_PAGE.ENGLISH'
    },
    [SupportedLanguagesEnum.AFRIKAANS]:{
      TRANSLATION_KEY:'LANGUAGE_PAGE.AFRIKAANS'
    },
    [SupportedLanguagesEnum.RUSSIAN]:{
      TRANSLATION_KEY:'LANGUAGE_PAGE.RUSSIAN'
    }
  }

  get supportedLanguages(){
    return this._supportedLanguages
  }

  constructor(
    private translateService: TranslateService,
    private preferencesService:PreferencesService
  ) {
    
   }

  public getLocalizedValue(value: string) {
    return this.translateService.instant(value) as string
  }

  public useLanguage(value: SupportedLanguagesEnum): void {
    this.translateService.use(value);
    this.translateService.setDefaultLang(value);
    this.preferencesService.saveLanguage(value)
  }

  public resolveAppLanguage(): void {
    this.preferencesService.retrieveLanguage().then((res: SupportedLanguagesEnum) => {
      const storage = res;
      const browser = this.translateService.getBrowserLang() as SupportedLanguagesEnum;
      if(storage && storage.length > 0 && this.isSupported(storage)) {
        console.log('Using stored language: ' + storage);
        this.useLanguage(storage);
      } else if(this.isSupported(browser)) {
        console.log('Stored language not found, reverting to browser language: ' + browser);
        this.useLanguage(browser);
      } else {
        console.log('Browser language not found, reverting to english...');
        this.useLanguage(SupportedLanguagesEnum.ENGLISH);
      }
    }).catch((e: object) => {
      console.log('Eish...Error occurred while resolving language: ' + e );
    });
  }

  private isSupported(value: string): boolean {
    const supported = Object.keys(this.supportedLanguages);
    if(JSON.stringify(supported).includes(value)) {
      return true;
    }
    return false;
  }
}
