import { Injectable } from '@angular/core';
import { GetOptions, Preferences, SetOptions } from '@capacitor/preferences';
import { Location, LocationStringValues, Shop } from '../models/interfaces';
import { SupportedLanguagesEnum } from './translation.service';

enum PreferencesKeys{
  USER_ID_AND_MAIL='user',
  USER_SAVED_LOCATIONS='location_history',
  VIEWED_INTRO='viewed_intro',
  VIEWED_HOME='viewed_home',
  VIEWED_LOCATION_SEARCH='viewed_location_search',
  VIEWED_FILTER='viewed_filter',
  VIEWED_NOTIFICATION_MAP='viewed_notification_map',
  VIEWED_LOCATION_MAP='viewed_location_map',
  VIEWED_WALLET_CARDS='viewed_wallet_cards',
  VIEWED_WALLET_VOUCHERS='viewed_wallet_vouchers',
  LANGUAGE="language"
}

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  constructor() { }

  async clear(){
    await Preferences.clear();
  }

  async deleteUserSavedLocation(location:Location) {
      try {
        let storedLocations = await this.retrieveUserSavedLocations()
        let newLocations = storedLocations.filter((loc)=>{
          return loc.readableAddress != location.readableAddress
        })
        await this.save(PreferencesKeys.USER_SAVED_LOCATIONS,newLocations);
      } catch (error) {
        this.handleError(error)
      }
  }

  async saveUserSavedLocations(locations:Location[]){
    try {
      await this.save(PreferencesKeys.USER_SAVED_LOCATIONS,locations)
    } catch (error) {
      this.handleError(error)
    }
  };

  async retrieveUserSavedLocations(){
    try {
      let stringLocations:LocationStringValues[] =  await this.retrieve(PreferencesKeys.USER_SAVED_LOCATIONS);
      if(!stringLocations){
        return []
      }
      let locations:Location[] = stringLocations.map((loc)=>{
        return {
         lat:parseFloat(loc.lat),
         lng:parseFloat(loc.lng),
         accuracy:parseFloat(loc.accuracy),
         readableAddress:loc.readableAddress,
         radius:parseFloat(loc.radius),
        }
       });
      return locations
    } catch (error) {
      this.handleError(error)
    }
  };

  async saveLanguage(lang:SupportedLanguagesEnum){
    try {
      await this.save(PreferencesKeys.LANGUAGE,lang)
    } catch (error) {
      this.handleError(error)
    }
  };

  async retrieveLanguage(){
    return await this.retrieve(PreferencesKeys.LANGUAGE) as SupportedLanguagesEnum;
  };

  async saveHomeViewed(){
    try {
      await this.save(PreferencesKeys.VIEWED_HOME,'yes')
    } catch (error) {
      this.handleError(error)
    }
  };

  async retrieveHomeViewed(){
    return await this.retrieve(PreferencesKeys.VIEWED_HOME) as string;
  };

  async saveLocationSearchViewed(){
    try {
      await this.save(PreferencesKeys.VIEWED_LOCATION_SEARCH,'yes')
    } catch (error) {
      this.handleError(error)
    }
  };

  async retrieveLocationSearchViewed(){
    return await this.retrieve(PreferencesKeys.VIEWED_LOCATION_SEARCH) as string;
  };

  async saveFilterViewed(){
    try {
      await this.save(PreferencesKeys.VIEWED_FILTER,'yes')
    } catch (error) {
      this.handleError(error)
    }
  };

  async retrieveFilterViewed(){
    return await this.retrieve(PreferencesKeys.VIEWED_FILTER) as string;
  };

  async saveNotificationMapViewed(){
    try {
      await this.save(PreferencesKeys.VIEWED_NOTIFICATION_MAP,'yes')
    } catch (error) {
      this.handleError(error)
    }
  };

  async retrieveNotificationMapViewed(){
    return await this.retrieve(PreferencesKeys.VIEWED_NOTIFICATION_MAP) as string;
  };

  async saveLocationMapViewed(){
    try {
      await this.save(PreferencesKeys.VIEWED_LOCATION_MAP,'yes')
    } catch (error) {
      this.handleError(error)
    }
  };

  async retrieveLocationMapViewed(){
    return await this.retrieve(PreferencesKeys.VIEWED_LOCATION_MAP) as string;
  };

  async saveWalletCardsViewed(){
    try {
      await this.save(PreferencesKeys.VIEWED_WALLET_CARDS,'yes')
    } catch (error) {
      this.handleError(error)
    }
  };

  async retrieveWalletCardsViewed(){
    return await this.retrieve(PreferencesKeys.VIEWED_WALLET_CARDS) as string;
  };

  async saveWalletVouchersViewed(){
    try {
      await this.save(PreferencesKeys.VIEWED_WALLET_VOUCHERS,'yes')
    } catch (error) {
      this.handleError(error)
    }
  };

  async retrieveWalletVouchersViewed(){
    return await this.retrieve(PreferencesKeys.VIEWED_WALLET_VOUCHERS) as string;
  };

  async saveBasicIntroViewed(){
    try {
      await this.save(PreferencesKeys.VIEWED_INTRO,'yes')
    } catch (error) {
      this.handleError(error)
    }
  };

  async retrieveBasicIntroViewed(){
    return await this.retrieve(PreferencesKeys.VIEWED_INTRO) as string;
  };


  async saveUserIdAndEmail(id:string,email:string) {
    try {
      await this.save(PreferencesKeys.USER_ID_AND_MAIL,{id,email})
    } catch (error) {
     this.handleError(error)
    }
 }

 async retrieveUserIdAndEmail() {
     try {
       return await this.retrieve(PreferencesKeys.USER_ID_AND_MAIL) as {id:string,email:string}
     } catch (error) {
       this.handleError(error)
     }
 }

 async retrieveRestaurantFromStorage(restaurantId:string){
  let getOptions:GetOptions={
    key:restaurantId,
  }
  return this.parse((await (await Preferences.get(getOptions)).value)) || null;
}

 async saveRestaurantInStorage(restaurant:Shop){
  let setOptions:SetOptions={
    key:restaurant.id,
    value:this.stringify(restaurant)
  }
  return await Preferences.set(setOptions)
 }

 private async retrieve(key:PreferencesKeys){
  return this.parse((await Preferences.get({key})).value)
 }

 private async save(key:PreferencesKeys,value:any){
  return await Preferences.set({key,value:this.stringify(value)})
 }

 private stringify(anything){
  return JSON.stringify(anything)
 }

 private parse(string:string){
  return JSON.parse(string)
 }

 private handleError(error){
  console.log(error);
  throw new Error('PreferencesService error: '+error.message)
 }
}
