import { Injectable } from '@angular/core';
import { Deal, WalletLoyaltyCard, YumDealzUser } from '../models/interfaces';
import { DatabaseService } from './database.service';
import { User } from '@firebase/auth';
import { NetworkStatusService } from './network-status.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserIdService } from './user-id.service';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyCardService {
  userLoyaltyCards$:BehaviorSubject<WalletLoyaltyCard[]> = new BehaviorSubject([])
  private _loyaltyCards:WalletLoyaltyCard[]=[]
  get loyaltyCards(){
    return this._loyaltyCards
  }
  private loyaltyCardSubscription:Subscription=null
  constructor(
    private database:DatabaseService,
    public networkStatusService:NetworkStatusService,
    private userIdService:UserIdService
    ) { 
      this.userIdService.userId$.subscribe((userId) => {
        if(!userId) return;
        this.listenToUserLoyaltyCards(true);
      });
    }

  async listenToUserLoyaltyCards(force=false) {
    if(force && this.loyaltyCardSubscription){
      this.loyaltyCardSubscription.unsubscribe();
    }
    if(!this.loyaltyCardSubscription||this.loyaltyCardSubscription.closed){
      this.database.listenToYumDealzUserIncompleteLoyaltyCards(this.userLoyaltyCards$);
      this.loyaltyCardSubscription = this.userLoyaltyCards$.subscribe((loyaltyCards)=>{
        this._loyaltyCards = loyaltyCards
      })
    }else{
      console.log('Already listening. subscribe to the active subject instead');
    }
  }

  giveUserLoyaltyCardsAtShop(shopUserId:string){
    return this.loyaltyCards.filter((card)=>{
      return card.userId == shopUserId
    });
  }

  giveCardInWalletForThisSpecial(spesh:Deal){
    let foundspesh = this._loyaltyCards.filter(special=>{
      return special.special_id==spesh.id;
    })[0]
    return foundspesh;
  }

  isSpecialInUserWallet(special:Deal){
    return this._loyaltyCards.filter(spesh=>{
      return spesh.special_id==special.id
    }).length > 0
 }

 joinLoyalty(special:Deal,fbUser:User) {
      const successResult= {
          status:'success',
      }
      const errorResult={
          status:'error',
          message:''
      }
     return new Promise<{status:string}>(async (resolve, reject) => {
        try {
            const uid = fbUser.uid;
            
            if(!uid){
                errorResult.message='user uid in context was null or undefined'
                reject(errorResult);
                return;
            }

            if(!special){
                errorResult.message='no special passed to function'
                reject(errorResult);
                return;
            }

            if(!special.user_uid || !special.id || !special.restaurant_id || !special.expiresAfter){
                errorResult.message='Must supply resUserId, special_id, specialExpiresAfter and restaurantId. One of those were undefined or 0'
                reject(errorResult);
                return;
            }

            let restaurantOwnerUserDoc = await this.database.fetchRestaurantOwnerDocData(special.user_uid) // TODO shop owner user needs a type
            
            if(!restaurantOwnerUserDoc){
                errorResult.message='Can not join. The restaurant user id did not produce a doc when trying to fetch'
                reject(errorResult);
                return;
            }
            
            await this.database.minusOneFromShopOwnerCardCount(restaurantOwnerUserDoc)

            await this.database.newLoyaltyCard(special.user_uid,special.restaurant_id,special.id,special.expiresAfter)

            resolve(successResult);
        } catch (error) {
            console.log(error);
            errorResult.message = `Could not join ${!this.networkStatusService.isOnline()?'(You are offline)':'(Are you offline?)'}`
            reject(errorResult);
        }
     })
  };

  async receiveStampNFC(
    loyaltyCard:WalletLoyaltyCard,
    deal:Deal,
    tagScannedSerialNr,
    location:{lat:number,lng:number}
  ){
      let shopOwner = await this.database.fetchRestaurantOwnerDocData(loyaltyCard.userId)
      if(!shopOwner){
          throw 'The shopOwner exists but data is null?'
      }
      let shopOwnerTags = shopOwner.tags as string[]
      if(!shopOwnerTags){
          throw 'The shopOwner does not have any nfc tags'
      }
      let matchingTag = shopOwnerTags.find((tag)=>{
          return tag == tagScannedSerialNr
      })
      if(!matchingTag){
          throw 'The scanned tag does not belong to the shopOwner'
      }
      let newStickersCount = await this.receiveStamp(loyaltyCard,deal,location)
      return newStickersCount
  }

  async receiveStamp(
    loyaltyCard:WalletLoyaltyCard,
    deal:Deal,
    location:{lat:number,lng:number}
  ){
      if(loyaltyCard.stickers_count == null) {
          throw 'All stamps was collected'
      }
      let newStickersCount = await this.database.getStampTransaction(deal,loyaltyCard,location.lat,location.lng)
      return newStickersCount
  };
}
