<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content>
  <div id="formContainer">
    <form class="center-no-padding">
      <ion-item class="nicerInput" lines="full">
        <ion-label color="secondary" position="floating">{{"FORGOT_PW_PAGE.EMAIL" | translate }}</ion-label>
        <ion-input type="email" required [(ngModel)]="email" name="email"></ion-input>
      </ion-item>

      <ion-row>
        <ion-col>
          <ion-button type="submit" color="primary" expand="block" (click)="sendResetEmail()">{{"FORGOT_PW_PAGE.SEND" |
            translate}}</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <small>
            {{"ERRORS.PLEASE_PROVIDE_EMAIL_ADDRESS" | translate}}
          </small>
        </ion-col>
      </ion-row>
    </form>
  </div>
</ion-content>