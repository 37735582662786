import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StoryService } from 'src/app/services/story.service';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { SwiperOptions, Virtual,Pagination,EffectCards  } from 'swiper';
import { ModalController } from '@ionic/angular';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { Shop } from 'src/app/models/interfaces';
// install Swiper modules
SwiperCore.use([Virtual,Pagination]);


@Component({
  selector: 'app-story-viewer',
  templateUrl: './story-viewer.component.html',
  styleUrls: ['./story-viewer.component.scss'],
})
export class StoryViewerComponent  implements OnInit {
  @Input() shop:Shop;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  stories
  constructor(
    private storyService:StoryService,
    public assetLinksService:AssetlinksService,
    private modalController: ModalController
  ) { }

  async ngOnInit() {
    try {
      const stories = await this.storyService.fetchShopStories(this.shop.id);
      this.stories = stories
      console.log(stories);
    } catch (error) {
      console.log(error);
    }
  }

  return(data = null) {
    this.modalController.dismiss();
  }

  onSwiper(params) {
    const [swiper] = params;
    console.log(swiper);
    }
    
    onBeforeTransitionStart(params) {
    const [swiper, speed, internal] = params;
    console.log('beforeTransitionStart, speed: ' + speed);
    }
    
    onSlideChange() {
    console.log('slide change');
    }
    
    slideNext(){
    this.swiper.swiperRef.slideNext(100);
    }
    
    slidePrev(){
    this.swiper.swiperRef.slidePrev(100);
    }

}
