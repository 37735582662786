<ion-header class="ion-no-border">
  <ion-toolbar color="secondary">
     <ion-buttons slot="start">
      <ion-button (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <iframe [attr.src]="googleDocUrl | safeResourceUrl" frameborder="0"></iframe>
</ion-content>
