import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Injectable({
  providedIn: 'root'
})

export class DeeplinkService {
  private readonly domain: string = 'http://localhost:8100/';

  constructor(private router: Router) { }

  initializeDeeplinks(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const pathArray = event.url.split(this.domain);
      const appPath = pathArray.pop();

      // Handle logic on URL after

      if (appPath) {
        this.router.navigateByUrl(appPath);
      }
    });
  }
}
