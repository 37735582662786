import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { SafePipe } from '@it-era/ngx-safe-pipes';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.page.html',
  styleUrls: ['./terms-of-service.page.scss'],
})
export class TermsOfServicePage implements OnInit {
  googleDocUrl: string = "https://docs.google.com/document/d/e/2PACX-1vSXexfR1DKQZmpkWzCxRUm7Qa0yikDs-OxgRjITORWyXvUsX9QmPFCPbOHXYuzSkL7IoYosZPIOxp86/pub?embedded=true";
 
  constructor( public modalCtrl:ModalController) {
  }

  ngOnInit() {
  }

  async close(){
    await this.modalCtrl.dismiss()
  }

}
