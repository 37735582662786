import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

@Injectable({
  providedIn: 'root'
})
export class NativeSettingsService {

  constructor() { }

  /**
 * Note that the only supported option by Apple is "App".
 * Using other options might break in future iOS versions
 * or have your app rejected from the App Store.
 */
async openNativeAppSettings(){
  if(Capacitor.getPlatform()!='web'){
    await NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails, 
      optionIOS: IOSSettings.App
    })
  }
}


}
