import { Injectable } from '@angular/core';
import { Deal, Voucher } from '../models/interfaces';
import { DatabaseService } from './database.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserIdService } from './user-id.service';



@Injectable({
  providedIn: 'root'
})
export class VouchersService {
  userVouchers$:BehaviorSubject<Voucher[]> = new BehaviorSubject([])
  private _vouchers:Voucher[] = []
  get vouchers(){
    return this._vouchers
  }
  private vouchersSubscription:Subscription
  constructor(
    private database: DatabaseService,
    private userIdService:UserIdService
  ) { 
    this.userIdService.userId$.subscribe((userId) => {
      if(!userId) return;
      this.listenToUserVouchers(true);
    });
  }

  async listenToUserVouchers(force=false){
    if(force && this.vouchersSubscription){
      this.vouchersSubscription.unsubscribe();
    }
      if(!this.vouchersSubscription||this.vouchersSubscription.closed){
        this.database.listenToUserVouchers(this.userVouchers$);
        this.vouchersSubscription = this.userVouchers$.subscribe((vouchers)=>{
         this._vouchers = vouchers
        })
      }else{
        console.log('Already listening. subscribe to the active subject instead');
      }
  }

  claimVoucher(voucher:Voucher,endOfDayUtcUnix:number){
      return this.database.claimVoucher(voucher,endOfDayUtcUnix);
  }

  async redeemVoucher(voucher: Voucher){
      let date = new Date();
      let thisDay = date.getUTCDate();
      let thisYear = date.getUTCFullYear();
      let thisMonth = date.getUTCMonth();
      let utc_end_of_day_unix = Date.UTC(thisYear,thisMonth,thisDay,23,59,59,999);
      await this.database.redeemVoucher(voucher,utc_end_of_day_unix)
  };

  async redeemVoucherNFC(
    voucher:Voucher,
    tagScannedSerialNr:string
    ){
        let shopOwner = await this.database.fetchRestaurantOwnerDocData(voucher.userId)
        
        if(!shopOwner){
            throw 'The shopOwner exists but data is null?'
        }

        let shopOwnerTags = shopOwner.tags as string[]

        if(!shopOwnerTags){
           throw 'The shopOwner does not have any nfc tags'
        }

        let matchingTag = shopOwnerTags.find((tag)=>{
            return tag == tagScannedSerialNr
        })

        if(!matchingTag){
            throw 'The scanned tag does not belong to the shopOwner'
        }
        
        await this.redeemVoucher(voucher)
  };
}
