import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-search-results-stamp',
  templateUrl: './no-search-results-stamp.component.html',
  styleUrls: ['./no-search-results-stamp.component.scss'],
})
export class NoSearchResultsStampComponent  implements OnInit {
  @Input() mode:'button'
  @Input() behavior:'static'|'animate'
  constructor() { }

  ngOnInit() {}

}
