import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.page.html',
  styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage implements OnInit {
  googleDocUrl: string = "https://docs.google.com/document/d/e/2PACX-1vSiTQpHxRbk_fRdXsOHJhbUUWnxiLA6NFP2yM6dUPUHJoUII0-aX7Ta1RHkbNsx9Zi_le8Gt4wr19y-/pub?embedded=true";
 
  constructor( public modalCtrl:ModalController) {
  }

  ngOnInit() {
  }

  async close(){
    await this.modalCtrl.dismiss()
  }

}
