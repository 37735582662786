import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-scanning-qr-code-stamp',
  templateUrl: './scanning-qr-code-stamp.component.html',
  styleUrls: ['./scanning-qr-code-stamp.component.scss'],
})
export class ScanningQrCodeStampComponent  implements OnInit {
  @Input() mode:'button'
  constructor() { }

  ngOnInit() {}

}
