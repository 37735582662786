import { Component, Input, OnInit } from '@angular/core';

export interface NoHeaderButton {
  ionIcon:string,
  handler:(...args:any)=>any
}

@Component({
  selector: 'app-no-header',
  templateUrl: './no-header.component.html',
  styleUrls: ['./no-header.component.scss'],
})
export class NoHeaderComponent implements OnInit {
  @Input() backUrl?:string
  @Input() mainText:string
  @Input() subText:string
  @Input() endButton:NoHeaderButton
  @Input() startButton:NoHeaderButton
  @Input() toolBarColor:'primary'|'secondary'|'tertiary'|'dark'|'medium'|'light' = 'secondary'
  @Input() toolBarTitleColor:'primary'|'secondary'|'tertiary'|'dark'|'medium'|'light' = 'light'
  @Input() textAreaClick:()=>any;
  constructor() { }

  ngOnInit() {}

  runEndButtonHandler(){
    this.endButton.handler()
  }

  runStartButtonHandler(){
    this.startButton.handler()
  }
}
