import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-yumdealz-stamp',
  templateUrl: './yumdealz-stamp.component.html',
  styleUrls: ['./yumdealz-stamp.component.scss'],
})
export class YumdealzStampComponent  implements OnInit {
  @Input() mode:'button'
  constructor() { }

  ngOnInit() {}

}
