import { ErrorHandler, Injectable } from '@angular/core';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler{

  constructor(
    alerts:AlertService
  ) { }

  handleError(error: any) {
    // Handle the error here
   if(error instanceof Error){}
   
  }
}
