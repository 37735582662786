import { Injectable } from '@angular/core';
import { BarcodeScanner, ScanResult } from '@capacitor-community/barcode-scanner';
import { ToastServiceService } from './toast-service.service';
import { HapticsService } from './haptics.service';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerService {
  crosshairId = "QrCrosshair";
  crosshairContainerId = "QrCrossHairContainer";
  private crosshairContainer: HTMLElement;
  private _isScanning$ = new BehaviorSubject<boolean>(false)
  get isScanning$() {
    return this._isScanning$
  }
  constructor(
    private toasts: ToastServiceService,
    private hapticsService: HapticsService,
    private ts: TranslationService,
  ) {
    this.createScannerElements();
  }


  async scan() {
    try {
      let result: ScanResult
      // TODO Check camera permission
      // This is just a simple example, check out the better checks in plugin repo
      this.hideBackground();
      if (!Capacitor.isNativePlatform()) {
        result = await this.mimicScan()
      } else {
        const grantedPermission = await this.tryToAskPermission()
        if (!grantedPermission) {
          this.showBackground();
          return
        }
        result = await BarcodeScanner.startScan();
        await this.hapticsService.hapticsImpactMedium();
        this._isScanning$.next(true);
      }
      this.showBackground();
      return { result }
    } catch (err) {
      console.log(err);
      await this.toasts.showToast(this.ts.getLocalizedValue('BARCODE_SCANNER_SERVICE.SCANNER_FAILED'), '');
    }
  }

  async tryToAskPermission() {
    // check if user already granted permission
    const status = await BarcodeScanner.checkPermission({ force: false });

    if (status.granted) {
      // user granted permission
      return true;
    }

    if (status.denied) {
      // user denied permission
      this.showOpenSettingsToast();
      return false;
    }

    if (status.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (status.neverAsked) {
      // user has not been requested this permission before
      // it is advised to show the user some sort of prompt
      // this way you will not waste your only chance to ask for the permission

      const c = confirm(this.ts.getLocalizedValue('BARCODE_SCANNER_SERVICE.NEED_CAMERA_PERMISSION'));
      if (!c) {
        return false;
      }
    }

    if (status.restricted || status.unknown) {
      // ios only
      // probably means the permission has been denied
      this.showOpenSettingsToast();
      return false;
    }

    // user has not denied permission
    // but the user also has not yet granted the permission
    // so request it
    const statusRequest = await BarcodeScanner.checkPermission({ force: true });

    if (statusRequest.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (statusRequest.granted) {
      // the user did grant the permission now
      return true;
    }

    // user did not grant the permission, so he must have declined the request
    return false;
  };

  private async showOpenSettingsToast() {
    await this.toasts.showToast(this.ts.getLocalizedValue('BARCODE_SCANNER_SERVICE.DECLINED_CAMERA_ACCESS'), '', async () => {
      await BarcodeScanner.openAppSettings()
    },
      this.ts.getLocalizedValue('BARCODE_SCANNER_SERVICE.OPEN_SETTINGS'))
  }

  private async mimicScan() {
    this._isScanning$.next(true);
    let res = await new Promise<{
      hasContent,
      content: string,
      format: string
    }>((resolve) => {
      setTimeout(() => {
        let result = {
          hasContent: false,
          content: 'Test',
          format: ''
        }
        resolve(result)
      }, 10000)
    })
    return res
  }

  private async hideBackground() {
    if (Capacitor.isNativePlatform()) {
      await BarcodeScanner.hideBackground();
    }
    document.body.style.opacity = '0';
    document.body.style.background = 'transparent';
    this.addCrosshair()
  }

  private async showBackground() {
    document.body.style.opacity = '';
    document.body.style.background = '';
    if (Capacitor.isNativePlatform()) {
      await BarcodeScanner.showBackground();
    }
    this.rmCrosshair();
    this._isScanning$.next(false);
  }

  private createScannerElements() {
    this.crosshairContainer = document.createElement("div");

    // set the crosshairContainer style using CSS
    this.crosshairContainer.style.position = "fixed";
    this.crosshairContainer.id = this.crosshairContainerId;
    this.crosshairContainer.style.top = "50%";
    this.crosshairContainer.style.left = "50%";
    this.crosshairContainer.style.transform = "translate(-50%, -50%)";
    this.crosshairContainer.style.width = "100%";
    this.crosshairContainer.style.height = "100%";
    this.crosshairContainer.style.zIndex = "9999";

    // create a new div element to contain the crosshair
    let crosshair = document.createElement("div");

    // set the crosshair style using CSS
    crosshair.id = this.crosshairId;
    crosshair.style.opacity = "";
    crosshair.style.background = "";
    crosshair.style.position = "fixed";
    crosshair.style.top = "50%";
    crosshair.style.left = "50%";
    crosshair.style.transform = "translate(-50%, -50%)";
    crosshair.style.width = "15em";
    crosshair.style.height = "15em";
    crosshair.style.border = "0.3em dashed #74a44c";
    crosshair.style.borderRadius = "2em";
    crosshair.style.zIndex = "9999";

    // append the crosshair element to the container element
    this.crosshairContainer.appendChild(crosshair);


    // create a new button element for cancel
    var cancelButton = document.createElement("button");

    // set the cancel button style using CSS
    cancelButton.textContent = "Cancel";
    cancelButton.style.position = "absolute";
    cancelButton.style.bottom = "2em";
    cancelButton.style.left = "50%";
    cancelButton.style.transform = "translateX(-50%)";
    cancelButton.style.padding = "10px";
    cancelButton.style.borderRadius = "5px";
    cancelButton.style.backgroundColor = "#F44336";
    cancelButton.style.color = "#f4f5f8"; //var(--ion-color-light)
    cancelButton.style.width = "19em";
    cancelButton.style.height = "50px";

      // add an event listener to the cancel button to remove the container element when clicked
      cancelButton.addEventListener("click", async ()=>{
        if(Capacitor.isNativePlatform()){
          await BarcodeScanner.stopScan({resolveScan:true});
        }
        this.showBackground();
      });

    // append the cancel button to the container element
    this.crosshairContainer.appendChild(cancelButton);
  }

  private addCrosshair() {
    // append the crosshair element to the root element
    try {
      document.documentElement.appendChild(this.crosshairContainer);
    } catch (error) {
      //
    }
  }

  private rmCrosshair() {
    try {
      document.getElementById(this.crosshairContainerId).remove();
    } catch (error) {
      //
    }

  }
}
