import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root'
})
export class StoryService {

  constructor(
    private database:DatabaseService,
  ) { }

  async fetchShopStories(shopId:string){
      return this.database.getShopActiveStories(shopId);
  }
}
