import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from 'firebase/functions';
import { Deal, YumDealzUser, Voucher, WalletLoyaltyCard } from '../models/interfaces';
import { FirebaseService } from './firebase.service';


@Injectable({
  providedIn: 'root'
})
export class CloudfunctriggersService {
  functions:Functions
  constructor(
    private firebaseService:FirebaseService
  ) {
    this.functions = this.firebaseService.getFunctions();
   }

  async likeSpecial(special:Deal,user:YumDealzUser){
    const likeSpecial = httpsCallable(this.functions,'likeSpecial');
    await likeSpecial({special:{
      id:special.id,
      restaurant_id:special.restaurant_id,
      user_uid:special.user_uid,
      likes:special.likes
    },user:{
      likedSpecials:user.likedSpecials,
      uniqueDeviceId:user.uniqueDeviceId
    }});
  }


  async claimVoucher(voucher:Voucher){
    const claimVoucher = httpsCallable(this.functions,'claimVoucher');
    return claimVoucher({voucher:{
      id:voucher.uid,
      restaurant_id:voucher.restaurantUid,
      special_id:voucher.specialUid,
      user_id:voucher.userId
    }});
  }


  async increaseSpecialClickCount(special:Deal){
    const increaseSpecialClickCount = httpsCallable(this.functions,'increaseSpecialClickCount');
    await increaseSpecialClickCount({special});
   }

}
