import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-disabled-stamp',
  templateUrl: './location-disabled-stamp.component.html',
  styleUrls: ['./location-disabled-stamp.component.scss'],
})
export class LocationDisabledStampComponent  implements OnInit {
  @Input() mode:'button'
  constructor() { }

  ngOnInit() {}

}
