import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-cards-wallet-stamp',
  templateUrl: './empty-cards-wallet-stamp.component.html',
  styleUrls: ['./empty-cards-wallet-stamp.component.scss'],
})
export class EmptyCardsWalletStampComponent  implements OnInit {
  @Input() mode:'button'
  constructor() { }

  ngOnInit() {}

}
