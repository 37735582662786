import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Deal, Shop, WalletLoyaltyCard } from 'src/app/models/interfaces';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { LoyaltyCardService } from 'src/app/services/loyalty-card.service';
import { ScanService } from 'src/app/services/scan.service';

@Component({
  selector: 'app-loyalty-card-preview',
  templateUrl: './loyalty-card-preview.component.html',
  styleUrls: ['./loyalty-card-preview.component.scss'],
})
export class LoyaltyCardPreviewComponent  implements OnInit,OnDestroy{
  @Input() deal:Deal
  @Input() shop:Shop
  @Input() loyaltyCard:WalletLoyaltyCard
  @Input() today
  @Input() mode:'previewOnly'|'previewWithScan'='previewOnly'
  cardHeight = '60vh'
  private userLoyaltyCardsSubscription:Subscription;
  showScanInstructions = false;
  loading = true;
  constructor(
    private modalController:ModalController,
    public assetLinksService:AssetlinksService,
    private loyaltyCardService:LoyaltyCardService,
    public scanService:ScanService
  ) { }

  async ngOnInit() {
    this.setupVoucherAndLoyaltyCardsSubscriptions();
  }


  ngOnDestroy(): void {
    this.userLoyaltyCardsSubscription.unsubscribe();
  }

  async return(data = null) {
    await this.modalController.dismiss();
    
  }

  private setupVoucherAndLoyaltyCardsSubscriptions(){
    if(!this.userLoyaltyCardsSubscription || this.userLoyaltyCardsSubscription.closed){
      this.userLoyaltyCardsSubscription = this.loyaltyCardService.userLoyaltyCards$.subscribe(async(loyaltyCards)=>{
        this.loading=false;
        this.loyaltyCard = loyaltyCards.find(card=>{return card.id == this.loyaltyCard.id})
       
        if(!this.loyaltyCard){
          await this.return()
        }
      })
    }
  }

  async scan(){
    this.loading = true;
    
    const scanningSubscription = this.scanService.isScanning$.subscribe((bool)=>{
      this.showScanInstructions=bool;
    })
   
    const scanContent = await this.scanService.scanLoyaltyCard(this.loyaltyCard,this.deal);
    if(scanContent){
      await this.scanService.letUserGetStamp(scanContent,this.loyaltyCard,this.deal);
    }
    this.loading = false;
    scanningSubscription.unsubscribe()
  }
}
