import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Deal, Voucher, WalletCardInfo } from 'src/app/models/interfaces';
import { RestaurantPage } from 'src/app/pages/restaurant/restaurant.page';
import { AlertService } from 'src/app/services/alert.service';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { BarcodeScannerService } from 'src/app/services/barcode-scanner.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { GisService } from 'src/app/services/gis.service';
import { HapticsService } from 'src/app/services/haptics.service';
import { LocationService } from 'src/app/services/location.service';
import { NetworkStatusService } from 'src/app/services/network-status.service';
import { NfcService } from 'src/app/services/nfc-read.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { VouchersService } from 'src/app/services/vouchers.service';
import { YumdealzUserService } from 'src/app/services/yumdealz-user.service';
import { environment } from 'src/environments/environment';
import { ConfettiService } from 'src/app/services/confetti.service';
import { ShopDealzService } from 'src/app/services/shop-dealz.service';
import { ScanService } from 'src/app/services/scan.service';


@Component({
  selector: 'app-voucher-card',
  templateUrl: './voucher-card.component.html',
  styleUrls: ['./voucher-card.component.scss'],
})
export class VoucherCardComponent  implements OnInit,OnDestroy {
  @Input() voucher:Voucher;
  @Input() scanRange:number;
  @Input() inMemorySpecial:Deal;

  special:Deal;
  isButtonLoading
  /**
  * the current day
  */
  today:"Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";

  loading:boolean=true;
  cardInfo:WalletCardInfo = {
    id:null,
    kmFromSpecial:null,
    lat:null,
    lng:null,
    specialId:null,
    shopName:null
  };
  platformResumeSubscription:Subscription;

  @Output() onWantsToScan:EventEmitter<Voucher> = new EventEmitter()
  @Output() onSpecialFetched:EventEmitter<Deal> = new EventEmitter()
  @Output() onCardInfoEmit:EventEmitter<WalletCardInfo> = new EventEmitter();
  constructor(
    public assetLinksService:AssetlinksService,
    public alertService:AlertService,
    public yumDealzUserService:YumdealzUserService,
    public shopDealzService:ShopDealzService,
    public vouchersService:VouchersService,
    public dateTimeService:DateTimeService,
    private locationService:LocationService,
    private toasts:ToastServiceService,
    private hapticsService:HapticsService,
    private gisService:GisService,
    private platform:Platform,
    private modalCtrl:ModalController,
    private barcodeScannerService:BarcodeScannerService,
    private nfcReader:NfcService,
    private confettiService:ConfettiService,
    private network:NetworkStatusService,
    public scanService:ScanService
    ) { 
     
      this.today =  this.dateTimeService.giveLocalNowDay();
      this.platformResumeSubscription = this.platform.resume.subscribe(async()=>{     
        // and other stuff should happen here if the day changed...
        this.today =  this.dateTimeService.giveLocalNowDay();
      });
    }
  
    private calcKmFromSpecial(special:Deal){
      if(!this.locationService.isUserLocationSet()){
        return null
      }
      return this.gisService.getDistanceFromLocation(special.lng,special.lat,
        {lng:this.locationService.userLocation.lng,lat:this.locationService.userLocation.lat}
      )
    }

    ngOnDestroy(): void {
      if(this.platformResumeSubscription){
        this.platformResumeSubscription.unsubscribe()
      }
    }

  emitInitDetails(){
    this.onSpecialFetched.emit(this.special)
    this.cardInfo.id = this.voucher.uid,
    this.cardInfo.shopName = this.special.restaurant_name
    this.cardInfo.specialId = this.special.id
    this.cardInfo.lat = this.special.lat
    this.cardInfo.lng = this.special.lng
    this.cardInfo.kmFromSpecial = this.calcKmFromSpecial(this.special)
    this.onCardInfoEmit.emit(this.cardInfo)
  }

  async ngOnInit() {
    if(this.inMemorySpecial){
      this.special = this.inMemorySpecial
      this.emitInitDetails();
    }else{
      try {
        this.special = await this.shopDealzService.fetchaSpecial(this.voucher.userId,this.voucher.restaurantUid,this.voucher.specialUid);
        if(this.special){
          this.emitInitDetails();
        }
      } catch (error) {
        console.log(error);
      }
    }
    this.loading = false;
  }

  async viewRestaurant(special:Deal){

  }


   async redeemVoucher(){
    this.isButtonLoading = true;
    const scanContent = await this.scanService.scanVoucher(this.voucher);
    if(scanContent){
      await this.scanService.letUserRedeemVoucher(scanContent,this.voucher);
    }
    this.isButtonLoading = false;
  }

}
