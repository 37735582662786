export enum DAYS_OF_WEEK {
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  }

  export enum FIRESTORE_ROOT_COLLECTIONS {
    YUM_DEALS_USERS='anon_users',
    BUNDLES='bundles',
    MISC='misc',
    BUSINESS_OWNERS='users',
    PUSH_TOKENS='push_tokens'
  }

  export enum FIRESTORE_SUB_COLLECTIONS {
    VOUCHERS='vouchers',
    SPECIALS='specials',
    STORIES='stories',
    RESTAURANTS='restaurants',
    ORDERS='users',
    WALLET_LOYALTY_CARDS = 'specials_loyalty_progress',
    STAMPS = 'stamps'
  }

