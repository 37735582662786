import { Component, OnInit } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  ModalController } from '@ionic/angular';
import { User, UserCredential, sendEmailVerification } from 'firebase/auth';
import { AuthService } from 'src/app/services/auth.service';
import { RegexpatternsService } from 'src/app/services/regexpatterns.service';
import { ValidatorService } from 'src/app/services/validators.service';
import { AssetlinksService } from 'src/app/services/assetlinks.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
})
export class SignupPage implements OnInit {
  email
  pw
  firstname
  lastname
  agreesToTerms =true
  registerForm: FormGroup;
  isIosPlatform:boolean = false;
  constructor(
    public authService:AuthService,
    private fb: FormBuilder,
    private modalController: ModalController,
    private loadingService:LoadingService,
    private toasts:ToastServiceService,
    public assetLinksService:AssetlinksService,
  ) { }

  ngOnInit() {
    this.isIosPlatform = Capacitor.getPlatform()=='ios'
    this.registerForm = this.fb.group({
      email: ['',[
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(RegexpatternsService.getEmailValidatorPattern())]
      ],
      pw: ['',[Validators.required,
        Validators.maxLength(50),
        Validators.pattern(RegexpatternsService.getPasswordValidatorPattern()),
        ValidatorService.containsUpperCase,
        ValidatorService.containsDigits,
        ValidatorService.containsLowerCase,
        Validators.minLength(6)]],
      ppw_confirm:['',[]],
      firstname: ['',[
        Validators.pattern(RegexpatternsService.getAlphanumericValidatorPattern()),
        Validators.required,
        Validators.maxLength(50)]],
      lastname: ['',[
        Validators.pattern(RegexpatternsService.getAlphanumericValidatorPattern()),
        Validators.required,
        Validators.maxLength(50)]],
    });

    this.registerForm.setValidators(ValidatorService.confirmedValidator('pw','ppw_confirm'))
  }



  changeAgreesToTerms(e){
    this.agreesToTerms= e.detail.checked
  }

  async goToLogin(){
    await this.modalController.dismiss({wantsToLogIn:true});
  }
  
  async signUpWithEmail(){
    try {
      let {email,pw,firstname,lastname} = this.registerForm.value;
      email = email.trim();
      firstname = firstname.trim();
      lastname = lastname.trim();
      await  this.loadingService.showOrContinueShowingLoading()
      let cred = await this.authService.linkWithEmail(email,pw,firstname+' '+lastname) as UserCredential
      if(cred){
        await sendEmailVerification(cred.user);
        this.done(cred.user);
      }
      await this.loadingService.hideLoading()
    } catch (error) {
      await this.loadingService.hideLoading()
     this.authService.handleAuthErrors(error);
    }
 
  }

  async done(fbUser:User) {
    await this.modalController.dismiss({loggedIn:true,fbUser});
  }

  async cancel() {
    await this.modalController.dismiss({loggedIn:false});
  }

}
