import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-denied-stamp',
  templateUrl: './location-denied-stamp.component.html',
  styleUrls: ['./location-denied-stamp.component.scss'],
})
export class LocationDeniedStampComponent  implements OnInit {
  @Input() mode:'button'
  constructor() { }

  ngOnInit() {}

}
